/**
 * Exchanges Index
 * Exports all exchange implementations and provides factory functions
 */

import BinanceExchange from './binance';
import KrakenExchange from './kraken';
import CoinbaseExchange from './coinbase';
import BitgetExchange from './bitget';
import BTCMarketsExchange from './btc-markets';
import BybitExchange from './bybit';
import CoinSpotExchange from './coinspot';
import GeminiExchange from './gemini';
import IndependentReserveExchange from './independent-reserve';
import KuCoinExchange from './kucoin';

// Map of exchange implementations
const exchangeClasses = {
  'binance': BinanceExchange,
  'kraken': KrakenExchange,
  'coinbase': CoinbaseExchange,
  'bitget': BitgetExchange,
  'btcmarkets': BTCMarketsExchange,
  'bybit': BybitExchange,
  'coinspot': CoinSpotExchange,
  'gemini': GeminiExchange,
  'independentreserve': IndependentReserveExchange,
  'kucoin': KuCoinExchange
};

/**
 * Create an exchange instance
 * @param {string} exchangeId - Exchange ID to create
 * @param {Object} options - Options to pass to the exchange constructor
 * @returns {Object} - Exchange instance
 */
export function createExchange(exchangeId, options = {}) {
  const normalizedId = exchangeId.toLowerCase();
  
  if (!exchangeClasses[normalizedId]) {
    throw new Error(`Exchange ${exchangeId} not implemented`);
  }
  
  return new exchangeClasses[normalizedId](options);
}

/**
 * Get list of supported exchange IDs
 * @returns {Array<string>} - Array of exchange IDs
 */
export function getSupportedExchangeIds() {
  return Object.keys(exchangeClasses);
}

/**
 * Create instances of all supported exchanges
 * @param {Object} globalOptions - Options to apply to all exchanges
 * @param {Object} exchangeOptions - Exchange-specific options keyed by exchange ID
 * @returns {Object} - Object with exchange instances keyed by exchange ID
 */
export function createAllExchanges(globalOptions = {}, exchangeOptions = {}) {
  const instances = {};
  
  for (const id of getSupportedExchangeIds()) {
    const options = {
      ...globalOptions,
      ...(exchangeOptions[id] || {})
    };
    
    instances[id] = createExchange(id, options);
  }
  
  return instances;
}

// Export exchange classes for direct access
export {
  BinanceExchange,
  KrakenExchange,
  CoinbaseExchange,
  BitgetExchange,
  BTCMarketsExchange,
  BybitExchange,
  CoinSpotExchange,
  GeminiExchange,
  IndependentReserveExchange,
  KuCoinExchange
};

// Export default object with all functions
export default {
  createExchange,
  getSupportedExchangeIds,
  createAllExchanges
}; 