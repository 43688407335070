import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import api from '../api';
import SupplyPieChart from './SupplyPieChart';
import ExchangeCalculator from './ExchangeCalculator';

const formatMarketCap = (marketCap) => {
  if (!marketCap) return 'N/A';
  if (marketCap >= 1e9) {
    return `${(marketCap / 1e9).toFixed(2)}B`;
  }
  if (marketCap >= 1e6) {
    return `${(marketCap / 1e6).toFixed(2)}M`;
  }
  return `${marketCap.toLocaleString()}`;
};

const CoinDetail = () => {
  const { id } = useParams();
  const [coin, setCoin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    api.getCoin(id)
      .then(data => {
        setCoin(data.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!coin) return <div>Coin not found</div>;

  return (
    <div className="coin-detail-container">
      {/* Coin Header Section */}
      <div style={{
        backgroundColor: '#440000',
        padding: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px'
      }}>
        {/* Left Section: Logo + Name */}
        <div style={{ 
          display: 'flex', 
          gap: '20px', 
          alignItems: 'center',
          flex: '0 1 auto'
        }}>
          {/* Logo */}
          <div style={{ flexShrink: 0 }}>
            {coin.featuredImage ? (
              <img 
                src={`${process.env.REACT_APP_STRAPI_URL}${
                  coin.featuredImage.formats?.small?.url || coin.featuredImage.url
                }`}
                alt={coin.name}
                style={{ 
                  width: '100px', 
                  height: '100px', 
                  objectFit: 'contain',
                  display: 'block'
                }}
              />
            ) : (
              <div style={{ 
                width: '100px', 
                height: '100px', 
                backgroundColor: '#333',
                borderRadius: '50%'
              }} />
            )}
          </div>

          {/* Name Section */}
          <div>
            <h1 style={{ 
              color: 'white', 
              margin: '0 0 5px 0',
              fontSize: '32px'
            }}>{coin.symbol}</h1>
            {coin.whitePaperUrl ? (
              <a 
                href={coin.whitePaperUrl} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <h2 style={{ 
                  color: '#ff4444', 
                  margin: 0,
                  fontSize: '20px'
                }}>{coin.name}</h2>
              </a>
            ) : (
              <h2 style={{ 
                color: '#888', 
                margin: 0,
                fontSize: '20px'
              }}>{coin.name}</h2>
            )}
          </div>
        </div>

        {/* Right Section: Market Data + Supply Chart */}
        <div style={{ 
          display: 'flex', 
          gap: '20px',
          alignItems: 'center',
          flex: '0 1 auto'
        }}>
          {/* Market Data Section */}
          <div style={{ 
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '10px',
            minWidth: '300px'
          }}>
            <div style={{ color: '#888', textAlign: 'right' }}><b>Market Cap</b></div>
            <div style={{ color: 'white' }}>${formatMarketCap(coin.marketCap)}</div>
            
            <div style={{ color: '#888', textAlign: 'right' }}><b>Circulating Supply</b></div>
            <div style={{ color: 'white' }}>{formatMarketCap(coin.circulatingSupply)}</div>
            
            <div style={{ color: '#888', textAlign: 'right' }}><b>Max Supply</b></div>
            <div style={{ color: 'white' }}>{formatMarketCap(coin.maxSupply || 'Unlimited')}</div>
          </div>

          {/* Supply Chart Section */}
          <div style={{ flexShrink: 0 }}>
            <SupplyPieChart 
              circulatingSupply={coin.circulatingSupply}
              maxSupply={coin.maxSupply}    
              svgSize={100}
              radius={38}
              strokeWidth={24}
              displaySupply={false}
            />
          </div>
        </div>
      </div>

      {/* Exchange Calculator Section */}
      <div style={{ marginBottom: '20px' }}>
        <ExchangeCalculator coinSymbol={coin.symbol} />
      </div>

      {/* Description Section */}
      <div style={{ padding: '20px' }}>
        {coin.description?.map((block, index) => {
          if (block.type === 'paragraph') {
            return <p key={index} style={{ color: 'white' }}>
              {block.children?.map((child, childIndex) => 
                <span key={childIndex}>{child.text}</span>
              )}
            </p>;
          }
          if (block.type === 'list') {
            return <ul key={index} style={{ color: 'white' }}>
              {block.children?.map((item, itemIndex) => 
                <li key={itemIndex}>{item.children[0].text}</li>
              )}
            </ul>;
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default CoinDetail; 