import React, { useState, useEffect, useMemo } from 'react';
import ExchangeComparison from './ExchangeComparison';
import exchangeManager from '../api/api-manager';

const ExchangeCalculator = ({ coinSymbol }) => {
  const [selectedSymbol, setSelectedSymbol] = useState('');
  
  // Base trading pairs
  const baseTradingPairs = [
    'BTC/USDT', 'ETH/USDT', 'BTC/USD', 'ETH/USD',
    'XRP/USDT', 'ADA/USDT', 'SOL/USDT', 'DOT/USDT'
  ];
  
  // Dynamically create trading pairs list based on the current coin
  const tradingPairs = useMemo(() => {
    if (!coinSymbol) return baseTradingPairs;
    
    // Create trading pairs for this coin
    const coinPairs = [
      `${coinSymbol}/USDT`,
      `${coinSymbol}/USD`,
      `${coinSymbol}/BTC`,
      `${coinSymbol}/ETH`
    ];
    
    // Filter out duplicates and combine with base pairs
    const allPairs = [...new Set([...coinPairs, ...baseTradingPairs])];
    
    return allPairs;
  }, [coinSymbol, baseTradingPairs]);
  
  // Get supported exchanges from the manager
  const supportedExchanges = exchangeManager.getSupportedExchanges();

  // Set default trading pair when coin symbol changes
  useEffect(() => {
    if (coinSymbol) {
      const defaultPair = `${coinSymbol}/USDT`;
      setSelectedSymbol(defaultPair);
    } else {
      // Default to BTC/USDT if no coin symbol provided
      setSelectedSymbol('BTC/USDT');
    }
  }, [coinSymbol]);

  return (
    <div className="exchange-calculator-container">      
      {/* Direct exchange comparison with merged order book visualization */}
      <ExchangeComparison 
        initialSymbol={selectedSymbol}
        hideControls={false}
      />
      
    </div>
  );
};

export default ExchangeCalculator; 