/**
 * Binance Exchange Implementation
 * Based on the Python binance.py implementation
 */
import Exchange from './base-exchange';

class BinanceExchange extends Exchange {
  constructor(options = {}) {
    super({
      id: 'binance',
      name: 'Binance',
      apiUrl: 'https://api.binance.com',
      // Standard Binance fee structure
      takerFee: 0.001, // 0.10%
      makerFee: 0.001, // 0.10%
      ...options
    });
    
    // Common trading pairs that are supported on Binance
    this.supportedPairs = [
      'BTC/USDT', 'ETH/USDT', 'BNB/USDT', 'XRP/USDT', 'LTC/USDT',
      'BCH/USDT', 'EOS/USDT', 'LINK/USDT', 'DOT/USDT',
      'ADA/USDT', 'DOGE/USDT', 'SOL/USDT', 'AVAX/USDT',
      'MATIC/USDT'
    ];

    // Pre-initialize markets with known pairs
    this.markets = {};
    this.supportedPairs.forEach(pair => {
      this.markets[pair] = {
        id: pair.replace('/', ''),
        symbol: pair,
        active: true,
        takerFee: this.takerFee,
        makerFee: this.makerFee
      };
    });
    
    this.marketsLoaded = true;
    this.feesFetched = true;
    this.initialized = true;
  }

  async initialize() {
    return Promise.resolve(true);
  }

  supportsSymbol(symbol) {
    return this.supportedPairs.includes(symbol);
  }

  getTakerFee(symbol) {
    return this.takerFee;
  }

  /**
   * Format a standard trading pair for Binance
   * @param {string} symbol - Standard trading pair (e.g., "BTC/USDT")
   * @returns {string} - Binance-specific trading pair (e.g., "BTCUSDT")
   */
  formatSymbol(symbol) {
    return symbol.replace('/', '');
  }

  /**
   * Parse a Binance trading pair to standard format
   * @param {string} symbol - Binance-specific trading pair (e.g., "BTCUSDT")
   * @returns {string} - Standard trading pair (e.g., "BTC/USDT")
   */
  parseSymbol(symbol) {
    // This is a simplified version; real implementation would handle all Binance's trading pairs
    const baseQuotePairs = [
      { base: 'BTC', quote: 'USDT' },
      { base: 'ETH', quote: 'USDT' },
      { base: 'BNB', quote: 'USDT' },
      { base: 'SOL', quote: 'USDT' },
      { base: 'XRP', quote: 'USDT' },
      { base: 'ETH', quote: 'BTC' },
      { base: 'BNB', quote: 'BTC' },
      // Add more pairs as needed
    ];

    for (const pair of baseQuotePairs) {
      const binancePair = `${pair.base}${pair.quote}`;
      if (binancePair === symbol) {
        return `${pair.base}/${pair.quote}`;
      }
    }

    // If no match found, try to split based on common quote currencies
    const quoteCurrencies = ['USDT', 'BTC', 'ETH', 'BNB', 'USD', 'BUSD'];
    for (const quote of quoteCurrencies) {
      if (symbol.endsWith(quote)) {
        const base = symbol.substring(0, symbol.length - quote.length);
        return `${base}/${quote}`;
      }
    }

    return symbol; // Return as is if can't parse
  }

  async fetchOrderBook(symbol) {
    const response = await fetch(
      `http://localhost:3001/api/orderbook/binance/${symbol.replace('/', '_')}`
    );
    
    if (!response.ok) {
      throw new Error(`Cache server error: ${response.status}`);
    }
    
    const data = await response.json();
    
    // Apply consistent formatting to the orderbook data
    if (data && data.bids && data.asks) {
      // Ensure we have numeric values before formatting
      data.bids = data.bids.map(bid => {
        // Ensure bid is properly formatted as [price, quantity]
        if (Array.isArray(bid) && bid.length >= 2) {
          const price = typeof bid[0] === 'string' ? parseFloat(bid[0]) : bid[0];
          const quantity = typeof bid[1] === 'string' ? parseFloat(bid[1]) : bid[1];
          return [this.formatDecimal(price), quantity];
        }
        return bid; // Return as is if not in expected format
      });
      
      data.asks = data.asks.map(ask => {
        // Ensure ask is properly formatted as [price, quantity]
        if (Array.isArray(ask) && ask.length >= 2) {
          const price = typeof ask[0] === 'string' ? parseFloat(ask[0]) : ask[0];
          const quantity = typeof ask[1] === 'string' ? parseFloat(ask[1]) : ask[1];
          return [this.formatDecimal(price), quantity];
        }
        return ask; // Return as is if not in expected format
      });
    }
    
    return data;
  }

  /**
   * Format decimal numbers according to the rules:
   * 1. For numbers >= 1000, show as Xk (e.g. 6.3k)
   * 2. For numbers < 1, remove leading zero and show 4 numerals (e.g. .5967)
   * 3. For numbers between 1-999, show 4 significant digits total
   * @param {number} num - Number to format
   * @returns {string} Formatted number
   */
  formatDecimal(num) {
    // Handle non-numeric values
    if (num === null || num === undefined || isNaN(num)) {
      return 'N/A';
    }
    
    // Ensure we're working with a number
    const numValue = typeof num === 'string' ? parseFloat(num) : num;
    
    if (numValue >= 1000) {
      return (numValue / 1000).toPrecision(3) + 'k';
    } else if (numValue < 1) {
      // Remove leading zero and show 4 numerals
      return numValue.toFixed(4).replace('0.', '.');
    } else {
      // For numbers between 1-999, show 4 significant digits
      return numValue.toPrecision(4);
    }
  }
}

export default BinanceExchange; 