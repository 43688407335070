import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_STRAPI_URL || 'http://strapi.cryptofinder.com:1337',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`
    }
});

export default api;
