/**
 * BTC Markets Exchange Implementation
 * Based on the Python implementation
 */
import Exchange from './base-exchange';

class BTCMarketsExchange extends Exchange {
  constructor(options = {}) {
    // Call the parent constructor with BTC Markets-specific defaults
    super({
      id: 'btcmarkets',
      name: 'BTC Markets',
      apiUrl: 'https://api.btcmarkets.net',
      // Standard BTC Markets fee structure
      takerFee: 0.0022, // 0.22% default taker fee
      makerFee: 0.0022, // 0.22% default maker fee
      ...options
    });
    
    // Common trading pairs that are supported on BTC Markets
    // BTCMarkets primarily supports AUD pairs, not USDT
    this.supportedPairs = [
      'BTC/AUD', 'ETH/AUD', 'XRP/AUD', 'LTC/AUD', 'BCH/AUD',
      'EOS/AUD', 'XLM/AUD', 'ETC/AUD', 'BAT/AUD', 'LINK/AUD'
    ];

    // Pre-initialize markets with known pairs
    this.markets = {};
    this.supportedPairs.forEach(pair => {
      this.markets[pair] = {
        id: this.formatMarketId(pair),
        symbol: pair,
        active: true,
        takerFee: this.takerFee,
        makerFee: this.makerFee
      };
    });
    
    // Mark markets as loaded since we've manually defined them
    this.marketsLoaded = true;
    this.feesFetched = true;
    this.initialized = true;
  }

  /**
   * Format trading pair to BTC Markets market ID format
   * Converts BTC/AUD to BTC-AUD
   * @param {string} symbol - Trading pair
   * @returns {string} Formatted market ID
   */
  formatMarketId(symbol) {
    return symbol.replace('/', '-');
  }

  /**
   * Initialize the exchange
   * @returns {Promise<boolean>} Success status
   */
  async initialize() {
    return Promise.resolve(true);
  }

  /**
   * Check if the exchange supports a specific trading pair
   * @param {string} symbol - Trading pair to check
   * @returns {boolean} Whether the exchange supports the pair
   */
  supportsSymbol(symbol) {
    return this.supportedPairs.includes(symbol);
  }

  /**
   * Get taker fee for a symbol
   * @param {string} symbol - Trading pair
   * @returns {number} Taker fee as a decimal
   */
  getTakerFee(symbol) {
    return this.takerFee;
  }

  /**
   * Format symbol for API requests
   * @param {string} symbol - Standard trading pair (e.g., "BTC/AUD")
   * @returns {string} - BTC Markets-specific trading pair (e.g., "BTC-AUD")
   */
  formatSymbol(symbol) {
    return symbol.replace('/', '-');
  }

  /**
   * Fetch order book data through the cache server
   * @param {string} symbol - Trading pair in standard format (e.g., "BTC/AUD")
   * @returns {Promise<Object>} Order book with bids and asks
   */
  async fetchOrderBook(symbol) {
    try {
      const formattedSymbol = symbol.replace('/', '_');
      const response = await fetch(
        `http://localhost:3001/api/orderbook/btcmarkets/${formattedSymbol}`
      );
      
      if (!response.ok) {
        throw new Error(`Cache server error: ${response.status}`);
      }
      
      const data = await response.json();
      
      // Apply consistent formatting to the orderbook data
      if (data && data.bids && data.asks) {
        // Ensure we have numeric values before formatting
        data.bids = data.bids.map(bid => {
          // Ensure bid is properly formatted as [price, quantity]
          if (Array.isArray(bid) && bid.length >= 2) {
            const price = typeof bid[0] === 'string' ? parseFloat(bid[0]) : bid[0];
            const quantity = typeof bid[1] === 'string' ? parseFloat(bid[1]) : bid[1];
            return [this.formatDecimal(price), quantity];
          }
          return bid; // Return as is if not in expected format
        });
        
        data.asks = data.asks.map(ask => {
          // Ensure ask is properly formatted as [price, quantity]
          if (Array.isArray(ask) && ask.length >= 2) {
            const price = typeof ask[0] === 'string' ? parseFloat(ask[0]) : ask[0];
            const quantity = typeof ask[1] === 'string' ? parseFloat(ask[1]) : ask[1];
            return [this.formatDecimal(price), quantity];
          }
          return ask; // Return as is if not in expected format
        });
      }
      
      return data;
    } catch (error) {
      console.error(`BTC Markets: Error fetching order book for ${symbol}:`, error);
      throw error;
    }
  }

  /**
   * Format decimal numbers according to the rules:
   * 1. For numbers >= 1000, show as Xk (e.g. 6.3k)
   * 2. For numbers < 1, remove leading zero and show 4 numerals (e.g. .5967)
   * 3. For numbers between 1-999, show 4 significant digits total
   * @param {number} num - Number to format
   * @returns {string} Formatted number
   */
  formatDecimal(num) {
    // Handle non-numeric values
    if (num === null || num === undefined || isNaN(num)) {
      return 'N/A';
    }
    
    // Ensure we're working with a number
    const numValue = typeof num === 'string' ? parseFloat(num) : num;
    
    if (numValue >= 1000) {
      return (numValue / 1000).toPrecision(3) + 'k';
    } else if (numValue < 1) {
      // Remove leading zero and show 4 numerals
      return numValue.toFixed(4).replace('0.', '.');
    } else {
      // For numbers between 1-999, show 4 significant digits
      return numValue.toPrecision(4);
    }
  }
}

export default BTCMarketsExchange; 