import React, { useState, useEffect, useMemo, useRef } from 'react';
import exchangeManager from '../api/api-manager';
import '../App.css'; // Import the same CSS used by the coin list
import { debounce } from 'lodash';  // You'll need to install lodash if not already present
import { fetchOrderBook } from '../api';
import { isMobile } from '../utils/mobileHelper';
import GeminiExchange from '../api/exchanges/gemini';
import IndependentReserveExchange from '../api/exchanges/independent-reserve';
import CoinbaseExchange from '../api/exchanges/coinbase';
import KrakenExchange from '../api/exchanges/kraken';

/**
 * Format numbers according to our display rules:
 * 1. For numbers >= 1000, show as Xk (e.g. 6.3k)
 * 2. For numbers < 1, remove leading zero and show 4 numerals (e.g. .5967)
 * 3. For numbers between 1-999, show 4 significant digits total
 */
const formatNumber = (num) => {
  if (num == null || isNaN(parseFloat(num))) return 'N/A';
  
  // Ensure we're working with a number
  const numValue = typeof num === 'string' ? parseFloat(num) : num;
  
  if (numValue >= 1000) {
    return (numValue / 1000).toPrecision(3) + 'k';
  } else if (numValue < 1) {
    // Remove leading zero and show 4 numerals
    return numValue.toFixed(4).replace('0.', '.');
  } else {
    // For numbers between 1-999, show 4 significant digits
    return numValue.toPrecision(4);
  }
};

// Market Depth Visualization Component
const MarketDepthVisualization = ({ selectedCurrency, selectedExchange, depth, compact = false }) => {
  if (!depth || !depth.bids || !depth.asks || depth.bids.length === 0 || depth.asks.length === 0) {
    return (
      <div className="market-depth-container">
        <div className="market-depth-error">
          No market depth data available for {selectedCurrency} on {selectedExchange}
        </div>
      </div>
    );
  }

  // Take the first 5 levels from each side
  const bids = depth.bids.slice(0, 5);
  const asks = depth.asks.slice(0, 5);

  // Find the maximum volume for normalization
  const maxBidVolume = Math.max(...bids.map(bid => Array.isArray(bid) ? bid[1] : bid.quantity));
  const maxAskVolume = Math.max(...asks.map(ask => Array.isArray(ask) ? ask[1] : ask.quantity));
  const maxVolume = Math.max(maxBidVolume, maxAskVolume);

  // For debugging
  console.log('Max Volume:', maxVolume, 'Bid Volumes:', bids.map(b => Array.isArray(b) ? b[1] : b.quantity));

  return (
    <div className="market-depth-container">
      <div className="market-depth-visualization">
        {/* Bid Side (Green/Buy) */}
        <div className="bid-side">
          {bids.map((bid, i) => {
            const price = Array.isArray(bid) ? bid[0] : bid.price;
            const quantity = Array.isArray(bid) ? bid[1] : bid.quantity;
            const widthPercent = Math.min(95, Math.max(5, (quantity / maxVolume) * 100));
            
            return (
              <div key={`bid-${i}`} className="depth-bar bid-bar">
                <div className="bid-bar-container">
                  <div 
                    className="bid-bar-fill" 
                    style={{ width: `${widthPercent}%` }}
                  >
                    &nbsp;
                  </div>
                  <span className="depth-text bid-text">
                    <span className="bid-quantity">{formatNumber(quantity)}</span>
                    <span className="depth-separator"> @ </span>
                    <span className="bid-price">{formatNumber(price)}</span>
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        {/* Ask Side (Red/Sell) */}
        <div className="ask-side">
          {asks.map((ask, i) => {
            const price = Array.isArray(ask) ? ask[0] : ask.price;
            const quantity = Array.isArray(ask) ? ask[1] : ask.quantity;
            const widthPercent = Math.min(95, Math.max(5, (quantity / maxVolume) * 100));
            
            return (
              <div key={`ask-${i}`} className="depth-bar ask-bar">
                <div className="ask-bar-container">
                  <div 
                    className="ask-bar-fill" 
                    style={{ width: `${widthPercent}%` }}
                  >
                    &nbsp;
                  </div>
                  <span className="depth-text ask-text">
                    <span className="ask-quantity">{formatNumber(quantity)}</span>
                    <span className="depth-separator"> @ </span>
                    <span className="ask-price">{formatNumber(price)}</span>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

    </div>
  );
};

const ExchangeComparison = ({ initialSymbol, hideControls = false }) => {
  const [symbol, setSymbol] = useState(initialSymbol || 'BTC/USDT');
  const [amount, setAmount] = useState(1);
  const [action, setAction] = useState('buy');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const showOrderBookColumn = true;
  const [globalError, setGlobalError] = useState(null);
  const [exchangesLoaded, setExchangesLoaded] = useState(false);
  const [dataReceived, setDataReceived] = useState(false);
  const [isFallbackData, setIsFallbackData] = useState(false);

  // Define this function properly as a regular function inside the component
  const fetchExchangeData = async (exchange, symbol) => {
    try {
      console.log(`Fetching ${exchange} data for ${symbol}`);
      const orderbook = await fetchOrderBook(exchange, symbol);
      // Process the orderbook data as needed
      return orderbook;
    } catch (error) {
      console.error(`Error fetching ${exchange} data:`, error);
      return { error: error.message };
    }
  };

  // Helper function to format percentages
  const formatPercentage = (num, digits = 5) => {
    if (num === null || num === undefined) return 'N/A';
    return num.toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits });
  };

  // Extract the coin symbol from the trading pair
  const coinSymbol = useMemo(() => {
    if (!symbol) return '';
    return symbol.split('/')[0];
  }, [symbol]);

  // Base trading pairs
  const baseTradingPairs = [
    'BTC/USDT', 'ETH/USDT', 'BTC/USD', 'ETH/USD',
    'XRP/USDT', 'ADA/USDT', 'SOL/USDT', 'DOT/USDT'
  ];
  
  // Generate trading pairs for the current coin
  const tradingPairs = useMemo(() => {
    if (!coinSymbol) return baseTradingPairs;
    
    // Create trading pairs for this coin
    const coinPairs = [
      `${coinSymbol}/USDT`,
      `${coinSymbol}/USD`,
      `${coinSymbol}/BTC`,
      `${coinSymbol}/ETH`
    ];
    
    // Add the coin's pairs first, then add other common pairs
    const allPairs = [...new Set([...coinPairs, ...baseTradingPairs])];
    
    return allPairs;
  }, [coinSymbol, baseTradingPairs]);

  // Update symbol when initialSymbol changes
  useEffect(() => {
    if (initialSymbol) {
      setSymbol(initialSymbol);
    }
  }, [initialSymbol]);

  // Initialize exchanges when component mounts
  useEffect(() => {
    const initializeExchanges = async () => {
      try {
        await exchangeManager.initialize();
        setExchangesLoaded(true);
      } catch (error) {
        console.error("Failed to initialize exchanges:", error);
        setError("Failed to initialize exchanges. Please try again later.");
      }
    };
    
    initializeExchanges();
  }, []);

  // Add refs to track previous values
  const prevSymbol = useRef(symbol);
  const prevAction = useRef(action);

  // First, move handleCompare before the useMemo
  const handleCompare = async () => {
    if (!symbol || !amount || amount <= 0) {
      setError("Please enter a valid amount and select a trading pair");
      return;
    }
    
    setLoading(true);
    setError(null);
    setGlobalError(null);
    
    try {
      const comparisonResults = await exchangeManager.compareExchanges(symbol, amount, action);
      
      // Only sort if symbol or action changed (not just amount)
      if (symbol !== prevSymbol.current || action !== prevAction.current) {
        comparisonResults.sort((a, b) => 
          action === 'buy' 
            ? a.effectiveCost - b.effectiveCost
            : b.effectiveProceeds - a.effectiveProceeds
        );
        prevSymbol.current = symbol;
        prevAction.current = action;
      }
      
      // Check if we have a global error
      const globalErrorResult = comparisonResults.find(result => result.globalError);
      if (globalErrorResult) {
        throw new Error(globalErrorResult.errorMessage);
      }
      
      setResults(comparisonResults);
    } catch (error) {
      console.error("Comparison error:", error);
      setError(`Failed to compare exchanges: ${error.message}`);
      setResults([]);
    } finally {
      setLoading(false);
    }
  };

  // Then create the debounced version
  const debouncedCompare = useMemo(
    () => debounce(handleCompare, 500),
    [handleCompare] // Add handleCompare to dependencies
  );

  // Then use it in the useEffect
  useEffect(() => {
    if (symbol && exchangesLoaded && amount > 0) {
      debouncedCompare();
    }
    
    // Cleanup
    return () => {
      debouncedCompare.cancel();
    };
  }, [symbol, exchangesLoaded, amount, action, debouncedCompare]); // Add debouncedCompare to dependencies

  // Only show the controls if not hidden by parent
  const renderControls = () => {
    if (hideControls) return null;
    
    return (
      <div className="exchange-form-container">
        <div className="form-row">
          <label className="form-label">Trading Pair</label>
          <select
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
            className="form-select"
          >
            {tradingPairs.map(pair => (
              <option key={pair} value={pair}>{pair}</option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <label className="form-label">Amount</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(parseFloat(e.target.value))}
            min="0.0001"
            step="0.0001"
            className="form-input"
          />
        </div>
        <div className="form-row">
          <label className="form-label">Action</label>
          <select
            value={action}
            onChange={(e) => setAction(e.target.value)}
            className="form-select"
          >
            <option value="buy">Buy</option>
            <option value="sell">Sell</option>
          </select>
        </div>
        <div className="form-row">
          <button 
            onClick={loading ? null : handleCompare} 
            className="compare-button"
          >
            Compare
          </button>
        </div>
        
        <div className={`loading-indicator ${loading ? 'loading' : 'loaded'}`}></div>
      </div>
    );
  };

  // Add this helper function to format currency
  const formatCurrency = (amount) => {
    if (amount >= 1000) {
      return `$${(amount / 1000).toFixed(2)}k`;
    }
    return `$${amount.toFixed(2)}`;
  };

  // Add logging to component to track data flow
  useEffect(() => {
    console.log("ExchangeComparison mounted, isMobile:", isMobile());
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    setGlobalError(null);
    
    try {
      console.log(`Fetching data for ${symbol}`);
      const orderBookData = await fetchOrderBook(symbol);
      
      console.log("Order book data received:", 
        orderBookData ? `${orderBookData.bids?.length || 0} bids, ${orderBookData.asks?.length || 0} asks` : 'No data');
      
      // Safety check for valid data structure
      if (!orderBookData || !Array.isArray(orderBookData.bids) || !Array.isArray(orderBookData.asks)) {
        throw new Error("Invalid order book data structure");
      }
      
      setDataReceived(true);
      setIsFallbackData(orderBookData.isFallback || false);
      
      setResults(orderBookData.results || []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(`Failed to load data: ${error.message}`);
      setResults([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="exchange-table-container">
      {/* Add the styles to the DOM */}
      <style>
        {`
          .exchange-row-disabled {
            opacity: 0.7,
            background-color: #222222
          }

          .exchange-status-unsupported {
            color: #888;
            font-style: italic;
            text-align: center;
          }

          .exchange-table th {
            background-color: #440000;
            font-weight: bold;
          }

          .exchange-table tr:hover:not(.exchange-row-disabled) {
            background-color: #550000;
          }

          .exchange-form-container {
            background-color: #440000;
            padding: 15px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            gap: 15px;
          }

          .form-row {
            margin: 0;
            display: flex;
            align-items: center;
            gap: 8px;
          }

          .form-label {
            color: white;
            font-weight: bold;
            margin: 0;
            white-space: nowrap;
          }

          /* Remove mb-2 class from the form rows in the JSX */
          .mb-2 {
            margin-bottom: 0;
          }

          /* Reset the form controls back to original */
          .form-select, .form-input, .form-button {
            background-color: #333;
            color: white;
            border: none;
            padding: 5px 10px;
            border-radius: 3px;
          }

          .form-select:hover, .form-input:hover, .form-button:hover {
            background-color: #444;
          }

          /* Desktop specific styles */
          .mobile-view {
            display: none;
          }

          /* Mobile specific styles */
          @media (max-width: 768px) {
            .desktop-cell {
              display: none;
            }

            .mobile-view {
              display: block;
              width: 100%;  /* Full width */
              padding: 1rem;
              background: rgba(68, 0, 0, 0.3);
              border-radius: 8px;
              margin-bottom: 1rem;
              box-sizing: border-box;  /* Include padding in width calculation */
            }

            /* Exchange header row */
            .mobile-exchange-info {
              display: flex;
              align-items: center;
              gap: 1rem;
              margin-bottom: 1.5rem;
            }

            .exchange-number {
              font-size: 1.1rem;
              opacity: 0.8;
            }

            .exchange-letter {
              background: rgba(255, 255, 255, 0.1);
              border-radius: 50%;
              width: 2.5rem;
              height: 2.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.2rem;
            }

            .exchange-name {
              font-size: 1.2rem;
              font-weight: 500;
            }

            /* Data layout */
            .mobile-data {
              display: flex;
              flex-direction: column;
              gap: 1.25rem;
            }

            .data-row {
              display: flex;
              justify-content: space-between;
              align-items: baseline;
            }

            .data-label {
              color: rgba(255, 255, 255, 0.6);
              font-size: 0.9rem;
            }

            .data-value {
              font-variant-numeric: tabular-nums;
              font-size: 1.1rem;
            }

            /* Market depth styling */
            .market-depth-cell {
              margin: 1rem -1rem;  /* Negative margin to make it full width */
              background: rgba(0, 0, 0, 0.2);
              padding: 0.75rem;
              width: calc(100% + 2rem);  /* Full width plus the negative margins */
            }

            /* Status colors */
            .status-filled {
              color: #90EE90;
            }
            
            .status-partial {
              color: #FFB84D;
            }

            /* Price and Total Cost emphasis */
            .price-value, .total-cost-value {
              font-weight: 500;
            }

            /* Secondary values slight de-emphasis */
            .fee-value, .slippage-value {
              opacity: 0.9;
            }

            .mobile-row {
              display: flex;
              justify-content: space-between;
              margin: 1rem 0;
              gap: 2rem;
            }

            .mobile-col {
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 0.375rem;
            }

            .market-depth-container {
              display: flex;
              justify-content: center;
              width: 100%;
            }

            .market-depth-visualization {
              width: 100%;
              max-width: 100%;
            }

            .exchange-table {
              width: 100%;  /* Make table full width */
              border-spacing: 0;  /* Remove spacing between cells */
              padding: 0;  /* Remove padding */
            }

            /* Remove any potential table cell spacing */
            td {
              padding: 0;
              width: 100%;
              display: block;
            }

            /* Remove the fee column that's showing up on the left */
            .fee-column-left {
              display: none;
            }
          }

          .depth-text {
            color: #ffffff;  // Default white text
          }

          .bid-quantity, .bid-price {
            color: #90EE90;  // Light green
          }

          .ask-quantity, .ask-price {
            color: #FFB6B6;  // Light red
          }

          .depth-separator {
            color: #888888;  // Grey for the @ symbol
          }

          .exchange-table {
            border-spacing: 1.5rem 0.75rem;  // Increase horizontal spacing between columns
          }

          // Right-align number columns
          .price-cell, .fee-cell, .slippage-cell, .spread-cell, .total-cost-cell {
            text-align: right;
            font-variant-numeric: tabular-nums;  // Use monospace numbers for alignment
          }

          // Left-align text columns
          .exchange-cell, .status-cell {
            text-align: left;
          }

          // Make the most important numbers more prominent
          .price-cell, .total-cost-cell {
            font-weight: 500;
          }

          // Slightly de-emphasize secondary numbers
          .fee-cell, .slippage-cell {
            color: rgba(255, 255, 255, 0.85);
          }

          // Add subtle divider between rows
          tr:not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }

          .status-filled {
            color: #90EE90;  // Match our bid green
          }
          
          .status-partial {
            color: #FFB84D;  // Warm orange
          }

          .table-header {
            position: sticky;
            top: 0;
            background: rgba(0, 0, 0, 0.8);
            backdrop-filter: blur(8px);
            z-index: 1;
            padding: 1rem 0;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.9);
          }
        `}
      </style>

      {renderControls()}
      
      {globalError && (
        <div className="global-error-message">
          <h3>Unable to compare exchanges</h3>
          <p>{globalError}</p>
          <p className="error-help">Please make sure CCXT is installed by running: <code>npm install ccxt</code></p>
        </div>
      )}
      
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
      
      {/* Only show table if there's no global error */}
      {!globalError && (
        <table className="exchange-table">
          <thead className="desktop-cell">
            <tr>
              <th>#</th>
              <th>Exchange</th>
              <th>Price</th>
              <th>Fee</th>
              <th>Slippage</th>
              {showOrderBookColumn && <th>Market Depth</th>}
              <th>Spread</th>
              <th>Total Cost</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result, index) => (
              <tr key={index} className={`exchange-row ${result.error ? 'exchange-row-disabled' : ''}`}>
                {/* Desktop View */}
                <td className="desktop-cell">{index + 1}</td>
                <td className="desktop-cell">
                  <div className="exchange-name">
                    <div className="exchange-letter">
                      {result.exchangeName?.charAt(0) || '?'}
                    </div>
                    <div>{result.exchangeName || 'Unknown'}</div>
                  </div>
                </td>

                {result.error ? (
                  <td colSpan={showOrderBookColumn ? "7" : "6"} 
                      className="exchange-status-unsupported" 
                      style={{ textAlign: 'center' }}>
                    Coin not supported by this exchange
                  </td>
                ) : (
                  <>
                    <td className="desktop-cell">{formatNumber(result.averagePrice)}</td>
                    <td className="desktop-cell">
                      {/* Add this before the fee calculations */}
                      {console.log('Fee calculation values:', {
                        price: result.price,
                        rawPrice: result.averagePrice, // This is likely the raw number we want
                        amount: amount,
                        fee: result.fee,
                        parsedFee: parseFloat(result.fee)
                      })}
                      {formatCurrency(result.averagePrice * amount * (parseFloat(result.fee) / 100))}
                    </td>
                    <td className="desktop-cell">↓ {formatNumber(result.slippage)}%</td>
                    {showOrderBookColumn && (
                      <td className="desktop-cell market-depth-cell">
                        <MarketDepthVisualization 
                          selectedCurrency={coinSymbol}
                          selectedExchange={symbol}
                          depth={result.marketDepth}
                        />
                      </td>
                    )}
                    <td className="desktop-cell">{formatNumber(result.spread)}</td>
                    <td className="desktop-cell">
                      {formatNumber(action === 'buy' ? result.effectiveCost : result.effectiveProceeds)}
                    </td>
                    <td className="desktop-cell">
                      <span className={result.filled ? 'status-filled' : 'status-partial'}>
                        {result.filled ? 'Filled' : 'Partial'}
                      </span>
                    </td>
                  </>
                )}

                {/* Mobile View */}
                <td className="mobile-view">
                  {/* Row 1: Exchange info */}
                  <div className="mobile-exchange-info">
                    <span className="exchange-number">{index + 1}</span>
                    <div className="exchange-letter">
                      {result.exchangeName?.charAt(0) || '?'}
                    </div>
                    <div className="exchange-name">{result.exchangeName || 'Unknown'}</div>
                  </div>

                  {/* Row 2: Price and Fee */}
                  <div className="mobile-row">
                    <div className="mobile-col">
                      <span className="data-label">Price</span>
                      <span className="data-value price-value">{formatNumber(result.averagePrice)}</span>
                    </div>
                    <div className="mobile-col">
                      <span className="data-label">Fee</span>
                      <span className="data-value fee-value">
                        {/* Add this before the fee calculations */}
                        {console.log('Fee calculation values:', {
                          price: result.price,
                          rawPrice: result.averagePrice, // This is likely the raw number we want
                          amount: amount,
                          fee: result.fee,
                          parsedFee: parseFloat(result.fee)
                        })}
                        {formatCurrency(result.averagePrice * amount * (parseFloat(result.fee) / 100))}
                      </span>
                    </div>
                  </div>

                  {/* Row 3: Market Depth (full width) */}
                  {showOrderBookColumn && (
                    <div className="market-depth-cell">
                      <MarketDepthVisualization 
                        selectedCurrency={coinSymbol}
                        selectedExchange={symbol}
                        depth={result.marketDepth}
                        compact={true}
                      />
                    </div>
                  )}

                  {/* Row 4: Slippage and Spread */}
                  <div className="mobile-row">
                    <div className="mobile-col">
                      <span className="data-label">Slippage</span>
                      <span className="data-value slippage-value">↓ {formatNumber(result.slippage)}%</span>
                    </div>
                    <div className="mobile-col">
                      <span className="data-label">Spread</span>
                      <span className="data-value">{formatNumber(result.spread)}</span>
                    </div>
                  </div>

                  {/* Row 5: Total Cost and Status */}
                  <div className="mobile-row">
                    <div className="mobile-col">
                      <span className="data-label">Total Cost</span>
                      <span className="data-value total-cost-value">
                        {formatNumber(action === 'buy' ? result.effectiveCost : result.effectiveProceeds)}
                      </span>
                    </div>
                    <div className="mobile-col">
                      <span className="data-label">Status</span>
                      <span className={`data-value ${result.filled ? 'status-filled' : 'status-partial'}`}>
                        {result.filled ? 'Filled' : 'Partial'}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            
            {loading && results.length === 0 && (
              <tr>
                <td colSpan={showOrderBookColumn ? "9" : "8"} className="loading-text">
                  Loading exchange data...
                </td>
              </tr>
            )}
            
            {!loading && results.length === 0 && !globalError && (
              <tr>
                <td colSpan={showOrderBookColumn ? "9" : "8"} className="no-data-text">
                  No exchange data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      
      {/* Legend */}
      <div className="market-depth-legend">
        <div className="bid-legend">
          <span className="color-dot bid-dot"></span> Bids (Buy)
        </div>
        <div className="ask-legend">
          <span className="color-dot ask-dot"></span> Asks (Sell)
        </div>
        <div className="legend-note">
          Wider bars = more liquidity at that price level
        </div>
        <div className="action-indicator">
          {action === 'buy' ? '↑ Asks affect buy price' : '↓ Bids affect sell price'}
        </div>
      </div>

    </div>
  );
};

export default ExchangeComparison; 