// The current implementation might be problematic
export const isMobile = () => {
  if (typeof navigator === 'undefined' || !navigator.userAgent) {
    return false;
  }
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

// Add debugging helper
export const getMobileDebugInfo = () => {
  return {
    userAgent: typeof navigator !== 'undefined' ? navigator.userAgent : 'undefined',
    isMobileDetected: isMobile(),
    platform: typeof navigator !== 'undefined' ? navigator.platform : 'unknown'
  };
}

// Ensure fallback data matches the expected format exactly
export const getFallbackData = (exchange, symbol) => {
  // Generate simple but correctly structured orderbook data
  const timestamp = new Date().toISOString();
  
  // Format that matches exactly what the server would return
  return {
    timestamp,
    exchange,
    symbol,
    bids: [
      [1000.00, 0.1],  // [price, amount]
      [999.90, 0.2],
      [999.80, 0.3],
    ],
    asks: [
      [1001.00, 0.1],
      [1001.10, 0.2],
      [1001.20, 0.3],
    ],
    cachedAt: timestamp, // Match the server timestamp format
    isFallback: true     // Flag to identify fallback data
  };
}

// Add this function to mobileHelper.js
export const setupMobileErrorHandling = () => {
  if (isMobile()) {
    console.log("Mobile device detected, enabling enhanced error handling");
    
    // Add global error handler for fetch operations
    const originalFetch = window.fetch;
    window.fetch = async function(...args) {
      try {
        const response = await originalFetch.apply(this, args);
        return response;
      } catch (error) {
        console.error("Mobile fetch error intercepted:", error);
        throw error;
      }
    };
    
    // Add global unhandled error and promise rejection handlers
    window.addEventListener('error', (event) => {
      console.error('Mobile global error caught:', event.error);
    });
    
    window.addEventListener('unhandledrejection', (event) => {
      console.error('Mobile unhandled promise rejection:', event.reason);
    });
  }
};

// Rename the existing getFallbackData function or add this as a new alias
export const getFallbackOrderBook = (symbol) => {
  return getFallbackData('fallback', symbol);
}; 