/**
 * KuCoin Exchange Implementation
 * Based on the Python implementation
 */
import Exchange from './base-exchange';

// List of CORS proxies to try
const CORS_PROXIES = process.env.NODE_ENV === 'development' 
  ? [
      'https://cors-anywhere.herokuapp.com/',
      'https://api.allorigins.win/raw?url=',
      'https://corsproxy.io/?'
    ]
  : [''];

class KuCoinExchange extends Exchange {
  constructor(options = {}) {
    // Call the parent constructor with KuCoin-specific defaults
    super({
      id: 'kucoin',
      name: 'KuCoin',
      apiUrl: 'https://api.kucoin.com/api/v1',
      takerFee: 0.001, // 0.10% default taker fee
      ...options
    });
    
    // Common trading pairs that are supported on KuCoin
    this.supportedPairs = [
      'BTC/USDT', 'ETH/USDT', 'XRP/USDT', 'LTC/USDT',
      'DOT/USDT', 'ADA/USDT', 'SOL/USDT', 'DOGE/USDT',
      'BTC/USDC', 'ETH/USDC', 'BTC/USD', 'ETH/USD',
      'BTC/EUR', 'ETH/EUR', 'BTC/AUD', 'ETH/AUD',
      'AVAX/USDT', 'BNB/USDT', 'MATIC/USDT'
    ];

    // Pre-initialize markets with known pairs
    this.markets = {};
    this.supportedPairs.forEach(pair => {
      this.markets[pair] = {
        id: this.formatSymbolForApi(pair),
        symbol: pair,
        active: true,
        takerFee: this.takerFee
      };
    });
    
    // Mark markets as loaded since we've manually defined them
    this.marketsLoaded = true;
    
    // Force initialized to true immediately
    this.initialized = true;
  }

  /**
   * Format symbol for KuCoin API
   * @param {string} symbol - Trading pair (e.g., "BTC/AUD")
   * @returns {string} Formatted trading pair (e.g., "BTC-AUD")
   */
  formatSymbolForApi(symbol) {
    // KuCoin uses dash separator (BTC/AUD -> BTC-AUD)
    return symbol.replace('/', '-');
  }

  /**
   * Initialize the exchange - always returns true immediately
   * @returns {Promise<boolean>} Success status
   */
  async initialize() {
    return Promise.resolve(true);
  }

  /**
   * Check if the exchange supports a specific trading pair
   * @param {string} symbol - Trading pair to check
   * @returns {boolean} Whether the exchange supports the pair
   */
  supportsSymbol(symbol) {
    // First check our pre-defined list
    if (this.supportedPairs.includes(symbol)) {
      return true;
    }
    
    // Otherwise assume it's not supported
    // For more comprehensive support, we could fetch all markets from the API
    return false;
  }

  /**
   * Get taker fee for a symbol
   * @param {string} symbol - Trading pair
   * @returns {number} Taker fee as a decimal
   */
  getTakerFee(symbol) {
    return this.takerFee; // 0.001 = 0.10%
  }

  /**
   * Fetch order book data
   * @param {string} symbol - Trading pair in standard format (e.g., "BTC/USDT")
   * @param {number} limit - Limit the number of orders (defaulting to 100 as per API)
   * @returns {Promise<Object>} Order book with bids and asks
   */
  async fetchOrderBook(symbol) {
    const response = await fetch(`http://localhost:3001/api/orderbook/kucoin/${symbol.replace('/', '_')}`);
    if (!response.ok) throw new Error(`Cache server error: ${response.status}`);
    return response.json();
  }
}

export default KuCoinExchange; 