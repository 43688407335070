/**
 * Coinbase Exchange Implementation
 * Based on the Python coinbase.py implementation
 */

import Exchange from './base-exchange';
import { getJson, formatUrl } from '../utils/api-utils';

/**
 * CoinbaseExchange class for interacting with the Coinbase API
 * @extends Exchange
 */
class CoinbaseExchange extends Exchange {
  /**
   * Create a new CoinbaseExchange instance
   * @param {Object} options - Options for the exchange
   */
  constructor(options = {}) {
    // Call the parent constructor with Coinbase-specific defaults
    super({
      id: 'coinbase',
      name: 'Coinbase',
      apiUrl: 'https://api.exchange.coinbase.com',
      takerFee: 0.006, // Default 0.6%
      makerFee: 0.004, // Default 0.4%
      ...options
    });
    
    // Common trading pairs that are supported on Coinbase
    this.supportedPairs = [
      'BTC/USD', 'BTC/USDT', 'BTC/EUR', 'BTC/GBP',
      'ETH/USD', 'ETH/USDT', 'ETH/EUR', 'ETH/BTC',
      'LTC/USD', 'LTC/USDT', 'LTC/EUR', 'LTC/BTC',
      'XRP/USD', 'XRP/USDT', 'XRP/EUR', 'XRP/BTC',
      'BCH/USD', 'BCH/USDT', 'BCH/EUR', 'BCH/BTC',
      'ADA/USD', 'ADA/USDT', 'SOL/USD', 'SOL/USDT',
      'AVAX/USD', 'AVAX/USDT', 'BNB/USD', 'BNB/USDT',
      'MATIC/USD', 'MATIC/USDT'
    ];

    // Initialize markets with known supported pairs
    this.markets = {};
    this.supportedPairs.forEach(pair => {
      this.markets[pair] = {
        id: this.formatSymbol(pair),
        symbol: pair,
        active: true,
        takerFee: this.takerFee
      };
    });
  }
  
  /**
   * Format a standard symbol to Coinbase format
   * @param {string} symbol - Standard symbol (e.g., "BTC/USD")
   * @returns {string} - Coinbase formatted symbol (e.g., "BTC-USD")
   */
  formatSymbol(symbol) {
    if (!symbol) return '';
    
    // Replace slash with dash for Coinbase format
    return symbol.replace('/', '-');
  }
  
  /**
   * Parse a Coinbase formatted symbol back to standard format
   * @param {string} symbol - Coinbase symbol (e.g., "BTC-USD")
   * @returns {string} - Standard symbol (e.g., "BTC/USD")
   */
  parseSymbol(symbol) {
    if (!symbol) return '';
    
    // Replace dash with slash for standard format
    return symbol.replace('-', '/');
  }
  
  /**
   * Check if a trading pair is supported
   * @param {string} symbol - Standard symbol format (e.g., "BTC/USD")
   * @returns {boolean} - Whether the pair is supported
   */
  supportsSymbol(symbol) {
    // First check our pre-defined list
    if (this.supportedPairs.includes(symbol)) {
      return true;
    }

    // Then check if we have it in our markets
    if (this.markets && this.markets[symbol]) {
      return true;
    }

    // For USDT pairs, check if we have an equivalent USD pair
    if (symbol.endsWith('/USDT')) {
      const baseSymbol = symbol.split('/')[0];
      const usdPair = `${baseSymbol}/USD`;
      return this.supportedPairs.includes(usdPair) || (this.markets && this.markets[usdPair]);
    }

    return false;
  }
  
  /**
   * Custom conversion for USDT pairs which aren't directly supported
   * @param {string} symbol - Standard symbol format (e.g., "BTC/USDT")
   * @returns {string} - Converted symbol (e.g., "BTC-USD")
   */
  convertUnsupportedPair(symbol) {
    // Convert USDT pairs to USD pairs
    if (symbol.endsWith('/USDT')) {
      const baseSymbol = symbol.split('/')[0];
      return `${baseSymbol}-USD`;
    }
    return this.formatSymbol(symbol);
  }
  
  /**
   * Load markets data from Coinbase
   * @returns {Promise<boolean>} - Success indicator
   */
  async loadMarkets() {
    try {
      // Fetch products (trading pairs) from Coinbase
      const url = `${this.apiUrl}/products`;
      
      try {
        const response = await getJson(url);
        
        // Process the response into our standard format
        for (const market of response) {
          const standardSymbol = `${market.base_currency}/${market.quote_currency}`;
          
          this.markets[standardSymbol] = {
            id: market.id,
            baseId: market.base_currency,
            quoteId: market.quote_currency,
            base: market.base_currency,
            quote: market.quote_currency,
            symbol: standardSymbol,
            active: market.status === 'online',
            takerFee: this.takerFee
          };
        }
        
        console.log(`Loaded ${Object.keys(this.markets).length} markets from Coinbase`);
      } catch (error) {
        console.warn("Could not fetch Coinbase markets, using predefined list:", error);
        // We'll continue with our predefined list
      }
      
      this.marketsLoaded = true;
      return true;
    } catch (error) {
      console.error("Error loading Coinbase markets:", error);
      // Still return true since we have our predefined list
      this.marketsLoaded = true;
      return true;
    }
  }
  
  /**
   * Get the taker fee for a specific trading pair
   * @param {string} symbol - Trading pair
   * @returns {number} - Taker fee as a decimal (e.g., 0.006 for 0.6%)
   */
  getTakerFee(symbol) {
    // Coinbase fees vary from 0.60% down to 0.05% based on 30-day volume
    // We use 0.60% as a fallback per the Python implementation
    return this.takerFee;
  }
  
  /**
   * Fetch order book for a trading pair
   * @param {string} symbol - Trading pair (e.g., "BTC/USD")
   * @param {number} limit - Number of levels to fetch
   * @returns {Promise<Object>} Order book with bids and asks
   */
  async fetchOrderBook(symbol, limit = 50) {
    try {
      console.log(`[Coinbase] Fetching orderbook for ${symbol} via cache`);
      // Format symbol for cache server
      const formattedSymbol = symbol.replace('/', '_').toUpperCase();
      
      const response = await fetch(`/api/orderbook/${this.id}/${formattedSymbol}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error(`[Coinbase] Error fetching orderbook:`, error);
      throw error;
    }
  }
  
  /**
   * Fetch ticker data for a symbol
   * @param {string} symbol - Standard symbol (e.g., "BTC/USD")
   * @returns {Promise<Object>} - Ticker data
   */
  async fetchTicker(symbol) {
    await this.loadMarketsIfNeeded();
    
    // Check if the pair is supported
    if (!this.supportsSymbol(symbol)) {
      throw new Error(`Trading pair ${symbol} not supported by coinbase`);
    }
    
    // Get the appropriate symbol for Coinbase
    const coinbaseSymbol = this.convertUnsupportedPair(symbol);
    
    try {
      // Get product ticker from Coinbase
      const url = `${this.apiUrl}/products/${coinbaseSymbol}/ticker`;
      const ticker = await getJson(url);
      
      // Format the response to our standard format
      return {
        symbol: symbol,
        timestamp: new Date(ticker.time).getTime(),
        datetime: ticker.time,
        bid: parseFloat(ticker.bid),
        ask: parseFloat(ticker.ask),
        last: parseFloat(ticker.price),
        high: undefined, // Not provided in basic ticker
        low: undefined, // Not provided in basic ticker
        volume: parseFloat(ticker.volume),
        change: undefined, // Not provided in basic ticker
        changePercent: undefined, // Not provided in basic ticker
        info: ticker
      };
    } catch (error) {
      console.error(`Error fetching Coinbase ticker for ${symbol}:`, error);
      throw new Error(`Failed to fetch Coinbase ticker: ${error.message}`);
    }
  }
}

export default CoinbaseExchange; 