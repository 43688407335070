/**
 * Kraken Exchange Implementation
 * Based on the Python kraken.py implementation
 */
import Exchange from './base-exchange';
import { getJson, formatUrl } from '../utils/api-utils';

// CORS proxy for development purposes
const CORS_PROXY = 'https://corsproxy.io/?';

// Add this formatter function at the top of the file
const formatNumber = (num) => {
  if (num == null) return 'N/A';
  
  // Handle thousands
  if (num >= 1000) {
    return `${(num/1000).toPrecision(3)}k`;
  }
  
  // For all other numbers, show 4 significant digits
  const formatted = num.toPrecision(4);
  
  // Remove leading zero for numbers less than 1
  return num < 1 ? formatted.replace('0.', '.') : formatted;
};

class KrakenExchange extends Exchange {
  constructor(options = {}) {
    // Call the parent constructor with Kraken-specific defaults
    super({
      id: 'kraken',
      name: 'Kraken',
      apiUrl: 'https://api.kraken.com',
      takerFee: 0.0026, // Default 0.26%
      makerFee: 0.0016, // Default 0.16%
      ...options
    });
    
    // Kraken uses special currency codes
    this.currencyAliases = {
      'BTC': 'XBT',
      'DOGE': 'XDG'
      // Add other aliases as needed
    };
    
    // Simplified special symbol mapping for Kraken
    // IMPORTANT: Define this BEFORE supportedPairs and markets to prevent undefined errors
    this.currencyMap = {
      'BTC': 'XBT',  // Kraken uses XBT instead of BTC
      'ETH': 'ETH',  // Some currencies don't need prefixes
      'USD': 'USD',
      'EUR': 'EUR',
      'XRP': 'XRP',
      'DOT': 'DOT',
      'SOL': 'SOL'
      // Add other special cases as needed
    };
    
    // Known Kraken pairs (using their exact format)
    this.krakenPairs = {
      'BTC/USD': 'XXBTZUSD',
      'ETH/USD': 'XETHZUSD',
      'DOT/USD': 'DOTUSD',  // No prefix for DOT
      'SOL/USD': 'SOLUSD',  // No prefix for SOL
      'XRP/USD': 'XXRPZUSD',
      'BTC/EUR': 'XXBTZEUR',
      'ETH/EUR': 'XETHZEUR',
      'DOT/EUR': 'DOTEUR',
      'SOL/EUR': 'SOLEUR',
      'ETH/BTC': 'XETHXXBT',
      'SOL/BTC': 'SOLXBT',
      'DOT/BTC': 'DOTXBT'
    };
    
    // Set up the initially supported pairs
    this.supportedPairs = [
      'BTC/USD', 'ETH/USD', 'XRP/USD', 'LTC/USD',
      'ADA/USD', 'DOT/USD', 'DOGE/USD', 'SOL/USD',
      'BTC/EUR', 'ETH/EUR', 'XRP/EUR', 'LTC/EUR',
      'BTC/USDT', 'ETH/USDT', 'XRP/USDT', 'LTC/USDT',
      'ADA/USDT', 'DOT/USDT', 'DOGE/USDT', 'SOL/USDT',
      'AVAX/USD', 'AVAX/USDT', 'BNB/USD', 'BNB/USDT',
      'MATIC/USD', 'MATIC/USDT'
    ];

    // Kraken uses different symbols internally
    this.symbolMapping = {
      'BTC/USD': 'XBTUSD',
      'ETH/USD': 'ETHUSD',
      'XRP/USD': 'XRPUSD',
      'LTC/USD': 'LTCUSD',
      'ADA/USD': 'ADAUSD',
      'DOT/USD': 'DOTUSD',
      'DOGE/USD': 'DOGEUSD',
      'SOL/USD': 'SOLUSD',
      'BTC/EUR': 'XBTEUR',
      'ETH/EUR': 'ETHEUR',
      'XRP/EUR': 'XRPEUR',
      'LTC/EUR': 'LTCEUR',
      'BTC/USDT': 'XBTUSDT',
      'ETH/USDT': 'ETHUSDT',
      'XRP/USDT': 'XRPUSDT',
      'LTC/USDT': 'LTCUSDT',
      'ADA/USDT': 'ADAUSDT',
      'DOT/USDT': 'DOTUSDT',
      'DOGE/USDT': 'DOGEUSDT',
      'SOL/USDT': 'SOLUSDT',
      'AVAX/USD': 'AVAXUSD',
      'AVAX/USDT': 'AVAXUSDT',
      'BNB/USD': 'BNBUSD',
      'BNB/USDT': 'BNBUSDT',
      'MATIC/USD': 'MATICUSD',
      'MATIC/USDT': 'MATICUSDT'
    };
    
    // Initialize markets with known supported pairs
    this.markets = {};
    this.supportedPairs.forEach(pair => {
      this.markets[pair] = {
        id: this.symbolMapping[pair] || this.formatSymbol(pair),
        symbol: pair,
        active: true,
        takerFee: this.takerFee
      };
    });
  }

  // Helper to get a proxied URL
  getProxiedUrl(url) {
    return `${CORS_PROXY}${encodeURIComponent(url)}`;
  }

  /**
   * Format a standard trading pair for Kraken
   * @param {string} symbol - Standard trading pair (e.g., "BTC/USD")
   * @returns {string} - Kraken-specific trading pair (e.g., "XXBTZUSD")
   */
  formatSymbol(symbol) {
    if (!symbol.includes('/')) return symbol;
    
    // Kraken has special formatting for pairs
    let [base, quote] = symbol.split('/');
    
    // Apply any known aliases
    base = this.currencyAliases[base] || base;
    quote = this.currencyAliases[quote] || quote;
    
    // For most crypto pairs, Kraken prepends "X" to the base and "Z" to fiat quotes
    if (['USD', 'EUR', 'CAD', 'GBP', 'JPY', 'CHF'].includes(quote)) {
      return `X${base}Z${quote}`;
    } else {
      return `X${base}X${quote}`;
    }
  }

  /**
   * Parse a Kraken trading pair to standard format
   * @param {string} symbol - Kraken-specific trading pair (e.g., "XXBTZUSD")
   * @returns {string} - Standard trading pair (e.g., "BTC/USD")
   */
  parseSymbol(symbol) {
    // If it's already in standard format, return it
    if (symbol.includes('/')) return symbol;
    
    // Handle Kraken's special format
    // Remove the X and Z prefixes and any special suffixes
    let cleanSymbol = symbol
      .replace(/^X/, '')
      .replace(/^Z/, '')
      .replace(/Z/, '/');
    
    // If we still don't have a pair, try to identify it
    if (!cleanSymbol.includes('/')) {
      // Try to split at the 3 or 4 character mark for common pairs
      if (cleanSymbol.length >= 6) {
        const baseLength = cleanSymbol.length === 6 ? 3 : 4;
        cleanSymbol = `${cleanSymbol.substring(0, baseLength)}/${cleanSymbol.substring(baseLength)}`;
      }
    }
    
    // Convert any aliases back to standard currency codes
    const [base, quote] = cleanSymbol.split('/');
    const standardBase = Object.entries(this.currencyAliases)
      .find(([standard, alias]) => alias === base)?.[0] || base;
    const standardQuote = Object.entries(this.currencyAliases)
      .find(([standard, alias]) => alias === quote)?.[0] || quote;
    
    return `${standardBase}/${standardQuote}`;
  }

  /**
   * Check if a Kraken pair would correspond to a standard pair
   * @param {string} standardSymbol - Standard format symbol (e.g., "BTC/USDT")
   * @returns {string|null} - Corresponding Kraken pair or null if not supported
   */
  getKrakenEquivalent(standardSymbol) {
    // Handle USDT pairs specially - Kraken typically uses USD instead
    if (standardSymbol.endsWith('/USDT')) {
      const base = standardSymbol.split('/')[0];
      return `${base}/USD`;  // Return the USD equivalent
    }
    return standardSymbol;
  }

  /**
   * Check if the exchange supports a specific trading pair
   * @param {string} symbol - Trading pair to check
   * @returns {boolean} Whether the exchange supports the pair
   */
  supportsSymbol(symbol) {
    // First check our pre-defined list
    if (this.supportedPairs.includes(symbol)) {
      console.log(`Kraken: Symbol ${symbol} is in supported pairs list`);
      return true;
    }

    // Handle USDT pairs - Kraken typically uses USD instead
    if (symbol.endsWith('/USDT')) {
      const base = symbol.split('/')[0];
      const usdEquivalent = `${base}/USD`;
      if (this.supportedPairs.includes(usdEquivalent)) {
        console.log(`Kraken: Will use ${usdEquivalent} instead of ${symbol}`);
        return true;
      }
    }

    // Then check if we have it in our markets
    const supported = this.markets && this.markets[symbol];
    console.log(`Kraken: Symbol ${symbol} supported: ${supported}`);
    return supported;
  }

  /**
   * Load markets data from Kraken
   * @returns {Promise<Object>} Markets data
   */
  async loadMarkets() {
    try {
      // Make a real API call to Kraken AssetPairs endpoint
      const apiUrl = `${this.apiUrl}/0/public/AssetPairs`;
      const url = this.getProxiedUrl(apiUrl);
      
      try {
        const data = await getJson(url);
        
        if (data.error && data.error.length > 0) {
          throw new Error(`Kraken API error: ${data.error.join(', ')}`);
        }
        
        // Process the response into a structured markets object
        if (data.result) {
          for (const [krakenSymbol, market] of Object.entries(data.result)) {
            // Parse to standard format
            const base = market.base.startsWith('X') ? market.base.substring(1) : market.base;
            const quote = market.quote.startsWith('Z') ? market.quote.substring(1) : market.quote;
            
            // Convert XBT to BTC
            const standardBase = base === 'XBT' ? 'BTC' : base;
            const standardQuote = quote;
            const standardSymbol = `${standardBase}/${standardQuote}`;
            
            // Extract fee info if available
            const takerFee = market.fees?.[0]?.[1] / 100 || this.takerFee; // Convert percentage to decimal
            
            this.markets[standardSymbol] = {
              id: krakenSymbol,
              baseAsset: standardBase,
              quoteAsset: standardQuote,
              status: 'TRADING', // Kraken doesn't provide status in AssetPairs
              takerFee: takerFee,
              makerFee: takerFee, // Kraken often has same maker/taker fees
              symbol: standardSymbol,
              active: true
            };
          }
        }
      } catch (error) {
        console.warn("Could not fetch Kraken markets, using predefined list:", error);
        // Continue with our predefined list
      }
      
      this.marketsLoaded = true;
      return this.markets;
    } catch (error) {
      console.error("Error loading Kraken markets:", error);
      // Still return true since we have our predefined list
      this.marketsLoaded = true;
      return this.markets;
    }
  }

  /**
   * Fetch ticker data for a symbol
   * @param {string} symbol - Trading pair in standard format (e.g., "BTC/USD")
   * @returns {Promise<Object>} Ticker data
   */
  async fetchTicker(symbol) {
    await this.loadMarketsIfNeeded();
    
    // Check if the pair is supported
    if (!this.supportsSymbol(symbol)) {
      throw new Error(`Trading pair ${symbol} not supported by kraken`);
    }
    
    // Get the equivalent supported pair if necessary
    const supportedSymbol = this.getKrakenEquivalent(symbol);
    const formattedSymbol = this.formatSymbol(supportedSymbol);
    
    try {
      // Make a real API call to Kraken ticker endpoint
      const apiUrl = formatUrl(`${this.apiUrl}/0/public/Ticker`, { pair: formattedSymbol });
      const url = this.getProxiedUrl(apiUrl);
      
      const data = await getJson(url);
      
      if (data.error && data.error.length > 0) {
        throw new Error(`Kraken API error: ${data.error.join(', ')}`);
      }
      
      // Process into a standardized ticker format
      // Kraken returns data in a different structure
      const tickerData = data.result[formattedSymbol];
      
      return {
        symbol: symbol,
        lastPrice: parseFloat(tickerData.c[0]),
        bidPrice: parseFloat(tickerData.b[0]),
        askPrice: parseFloat(tickerData.a[0]),
        volume: parseFloat(tickerData.v[1]), // 24h volume
        quoteVolume: null, // Kraken doesn't provide this directly
        timestamp: Date.now(),
        datetime: new Date().toISOString(),
        high: parseFloat(tickerData.h[1]), // 24h high
        low: parseFloat(tickerData.l[1]), // 24h low
        change: null, // Kraken doesn't provide this directly
        percentage: null, // Kraken doesn't provide this directly
        baseVolume: parseFloat(tickerData.v[1]),
        info: tickerData
      };
    } catch (error) {
      console.error(`Error fetching Kraken ticker for ${symbol}:`, error);
      throw new Error(`Failed to fetch Kraken ticker: ${error.message}`);
    }
  }

  /**
   * Get equivalent supported symbol
   * @param {string} symbol - Trading pair to check
   * @returns {string} Equivalent supported symbol
   */
  getSupportedSymbol(symbol) {
    // If the symbol is directly supported, return it
    if (this.supportedPairs.includes(symbol)) {
      return symbol;
    }
    
    // Handle USDT pairs - Kraken typically uses USD instead
    if (symbol.endsWith('/USDT')) {
      const base = symbol.split('/')[0];
      const usdEquivalent = `${base}/USD`;
      if (this.supportedPairs.includes(usdEquivalent)) {
        return usdEquivalent;
      }
    }
    
    return symbol; // Return original as fallback
  }

  /**
   * Fetch order book data
   * @param {string} symbol - Trading pair in standard format (e.g., "BTC/USD")
   * @param {number} limit - Limit the number of orders
   * @returns {Promise<Object>} Order book with bids and asks
   */
  async fetchOrderBook(symbol, limit = 50) {
    try {
      console.log(`[Kraken] Fetching orderbook for ${symbol} via cache`);
      // Format symbol for cache server
      const formattedSymbol = symbol.replace('/', '_').toUpperCase();
      
      const response = await fetch(`/api/orderbook/${this.id}/${formattedSymbol}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error(`[Kraken] Error fetching orderbook:`, error);
      throw error;
    }
  }
}

export default KrakenExchange; 