/**
 * Gemini Exchange Implementation
 * Based on the Python implementation
 */
import Exchange from './base-exchange';
import { getJson } from '../utils/api-utils';

// List of CORS proxies to try
const CORS_PROXIES = process.env.NODE_ENV === 'development' 
  ? [
      'https://cors-anywhere.herokuapp.com/',
      'https://api.allorigins.win/raw?url=',
      'https://corsproxy.io/?'
    ]
  : [''];

class GeminiExchange extends Exchange {
  constructor(options = {}) {
    // Call the parent constructor with Gemini-specific defaults
    super({
      id: 'gemini',
      name: 'Gemini',
      apiUrl: 'https://api.gemini.com',
      takerFee: 0.0035, // 0.35% default taker fee
      makerFee: 0.001,  // Default 0.10%
      ...options
    });
    
    // Common trading pairs that are supported on Gemini
    this.supportedPairs = [
      'BTC/USD', 'ETH/USD', 'BCH/USD', 'LTC/USD',
      'ZEC/USD', 'LINK/USD', 'BAT/USD', 'DAI/USD',
      'FIL/USD', 'AAVE/USD', 'COMP/USD', 'AVAX/USD',
      'DOT/USD', 'MATIC/USD', 'SOL/USD', 'XRP/USD'
    ];

    // Map USDT pairs to USD pairs since Gemini uses USD
    this.usdtToUsdMapping = {
      'BTC/USDT': 'BTC/USD',
      'ETH/USDT': 'ETH/USD',
      'BCH/USDT': 'BCH/USD',
      'LTC/USDT': 'LTC/USD',
      'ZEC/USDT': 'ZEC/USD',
      'LINK/USDT': 'LINK/USD',
      'BAT/USDT': 'BAT/USD',
      'DAI/USDT': 'DAI/USD',
      'FIL/USDT': 'FIL/USD',
      'AAVE/USDT': 'AAVE/USD',
      'COMP/USDT': 'COMP/USD',
      'AVAX/USDT': 'AVAX/USD',
      'DOT/USDT': 'DOT/USD',
      'MATIC/USDT': 'MATIC/USD',
      'SOL/USDT': 'SOL/USD',
      'XRP/USDT': 'XRP/USD'
    };

    // Pre-initialize markets with known pairs
    this.markets = {};
    this.supportedPairs.forEach(pair => {
      this.markets[pair] = {
        id: this.formatSymbolForApi(pair),
        symbol: pair,
        active: true,
        takerFee: this.takerFee
      };
    });
    
    // Mark markets as loaded since we've manually defined them
    this.marketsLoaded = true;
    
    // Force initialized to true immediately
    this.initialized = true;
  }

  /**
   * Format symbol for Gemini API
   * @param {string} symbol - Trading pair (e.g., "BTC/USD")
   * @returns {string} Formatted symbol for API (e.g., "btcusd")
   */
  formatSymbolForApi(symbol) {
    // First, check if this is a USDT pair that should be converted to USD
    let formattedSymbol = symbol;
    if (symbol.endsWith('/USDT') && this.usdtToUsdMapping[symbol]) {
      const usdEquivalent = this.usdtToUsdMapping[symbol];
      console.log(`Gemini: Converting ${symbol} to ${usdEquivalent} (USDT not supported by Gemini)`);
      formattedSymbol = usdEquivalent;
    }
    
    // Special handling for XRP - make sure we use the correct format
    if (formattedSymbol.includes('XRP/')) {
      console.log(`Gemini: Special handling for XRP pair ${formattedSymbol}`);
      // Convert pair to Gemini format (XRP/USD -> xrpusd)
      const apiSymbol = formattedSymbol.replace('/', '').toLowerCase();
      console.log(`Gemini: Formatted ${symbol} -> ${apiSymbol} for API (XRP special handling)`);
      return apiSymbol;
    }
    
    // Convert pair to Gemini format (BTC/USD -> btcusd)
    const apiSymbol = formattedSymbol.replace('/', '').toLowerCase();
    console.log(`Gemini: Formatted ${symbol} -> ${apiSymbol} for API`);
    return apiSymbol;
  }

  /**
   * Initialize the exchange - always returns true immediately
   * @returns {Promise<boolean>} Success status
   */
  async initialize() {
    return Promise.resolve(true);
  }

  /**
   * Check if the exchange supports a specific trading pair
   * @param {string} symbol - Trading pair to check
   * @returns {boolean} Whether the exchange supports the pair
   */
  supportsSymbol(symbol) {
    // Direct support check
    if (this.supportedPairs.includes(symbol)) {
      console.log(`Gemini: ${symbol} is directly supported`);
      return true;
    }
    
    // Check if this is a USDT pair that has a USD equivalent we support
    if (symbol.endsWith('/USDT') && this.usdtToUsdMapping[symbol]) {
      const usdEquivalent = this.usdtToUsdMapping[symbol];
      if (this.supportedPairs.includes(usdEquivalent)) {
        console.log(`Gemini: ${symbol} supported through ${usdEquivalent}`);
        return true;
      }
    }
    
    console.log(`Gemini: ${symbol} is NOT supported`);
    return false;
  }

  /**
   * Get taker fee for a symbol
   * @param {string} symbol - Trading pair
   * @returns {number} Taker fee as a decimal
   */
  getTakerFee(symbol) {
    return this.takerFee; // 0.0035 = 0.35%
  }

  /**
   * Fetch order book data
   * @param {string} symbol - Trading pair in standard format (e.g., "BTC/USD")
   * @param {number} limit - Limit the number of orders (not used by Gemini API directly)
   * @returns {Promise<Object>} Order book with bids and asks
   */
  async fetchOrderBook(symbol, limit = 100) {
    try {
      console.log(`[Gemini] Fetching orderbook for ${symbol} via cache`);
      const formattedSymbol = symbol.replace('/', '_').toUpperCase();
      
      const response = await fetch(`/api/orderbook/${this.id}/${formattedSymbol}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error(`[Gemini] Error fetching orderbook:`, error);
      throw error;
    }
  }
}

export default GeminiExchange; 