/**
 * API Utilities
 * Helper functions for making API requests with proper error handling
 */

/**
 * Make a fetch request with timeout
 * @param {string} url - The URL to fetch
 * @param {Object} options - Fetch options
 * @param {number} timeout - Timeout in milliseconds (default: 10000)
 * @returns {Promise<Response>} - Fetch response
 */
export async function fetchWithTimeout(url, options = {}, timeout = 10000) {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  
  const fetchOptions = {
    ...options,
    signal: controller.signal
  };
  
  try {
    const response = await fetch(url, fetchOptions);
    clearTimeout(id);
    return response;
  } catch (error) {
    clearTimeout(id);
    if (error.name === 'AbortError') {
      throw new Error(`Request timeout after ${timeout}ms`);
    }
    throw error;
  }
}

/**
 * Make a GET request and parse JSON response
 * @param {string} url - The URL to fetch
 * @param {number} timeout - Timeout in milliseconds
 * @returns {Promise<any>} - Parsed JSON response
 */
export async function getJson(url, timeout = 10000) {
  try {
    console.log(`Fetching: ${url}`);
    const response = await fetchWithTimeout(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    }, timeout);
    
    if (!response.ok) {
      let errorText;
      try {
        // Try to parse error response as JSON
        const errorJson = await response.json();
        errorText = errorJson.message || JSON.stringify(errorJson);
      } catch {
        // Fall back to text if not JSON
        errorText = await response.text().catch(() => 'Unknown error');
      }
      throw new Error(`HTTP Error ${response.status}: ${errorText}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error(`Error fetching ${url}:`, error);
    throw error;
  }
}

/**
 * Convert an object to URL query parameters
 * @param {Object} params - Parameters object
 * @returns {string} - URL query string
 */
export function objectToQueryParams(params) {
  return Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

/**
 * Format a URL with query parameters
 * @param {string} baseUrl - Base URL
 * @param {Object} params - Query parameters
 * @returns {string} - Full URL with query parameters
 */
export function formatUrl(baseUrl, params = {}) {
  const queryString = objectToQueryParams(params);
  return queryString ? `${baseUrl}?${queryString}` : baseUrl;
}

/**
 * Calculate exponential moving average (EMA)
 * @param {Array<number>} values - Array of numeric values
 * @param {number} period - EMA period
 * @returns {Array<number>} - Array of EMA values
 */
export function calculateEMA(values, period) {
  if (!values || values.length === 0) return [];
  
  const k = 2 / (period + 1);
  let ema = values[0];
  const result = [ema];
  
  for (let i = 1; i < values.length; i++) {
    ema = values[i] * k + ema * (1 - k);
    result.push(ema);
  }
  
  return result;
}

/**
 * Calculate volume-weighted average price (VWAP)
 * @param {Array<Object>} trades - Array of trade objects with price and volume
 * @returns {number} - VWAP value
 */
export function calculateVWAP(trades) {
  if (!trades || trades.length === 0) return 0;
  
  let sumPriceVolume = 0;
  let sumVolume = 0;
  
  for (const trade of trades) {
    sumPriceVolume += trade.price * trade.volume;
    sumVolume += trade.volume;
  }
  
  return sumVolume > 0 ? sumPriceVolume / sumVolume : 0;
}

/**
 * Parse a trading pair string into base and quote currencies
 * @param {string} symbol - Trading pair (e.g., "BTC/USDT")
 * @returns {Object} - Object with base and quote properties
 */
export function parseSymbol(symbol) {
  const parts = symbol.split('/');
  return {
    base: parts[0],
    quote: parts.length > 1 ? parts[1] : ''
  };
}

/**
 * Get current timestamp in milliseconds
 * @returns {number} - Current timestamp
 */
export function now() {
  return Date.now();
}

/**
 * Sleep for specified milliseconds
 * @param {number} ms - Milliseconds to sleep
 * @returns {Promise<void>}
 */
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
} 