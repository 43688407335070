/**
 * Independent Reserve Exchange Implementation
 * Based on the Python implementation
 */
import Exchange from './base-exchange';
import { getJson } from '../utils/api-utils';

// List of CORS proxies to try
const CORS_PROXIES = process.env.NODE_ENV === 'development' 
  ? [
      'https://corsproxy.io/?',
      'https://api.allorigins.win/raw?url=',
      'https://cors-anywhere.herokuapp.com/',
      'https://corsproxy.org/?',
      'https://cors.bridged.cc/'
    ]
  : [''];

class IndependentReserveExchange extends Exchange {
  constructor(options = {}) {
    // Call the parent constructor with Independent Reserve-specific defaults
    super({
      id: 'independentreserve',
      name: 'Independent Reserve',
      apiUrl: 'https://api.independentreserve.com',
      takerFee: 0.005, // Default 0.5%
      makerFee: 0.0025, // Default 0.25%
      ...options
    });
    
    // Common trading pairs that are supported on Independent Reserve
    this.supportedPairs = [
      'BTC/AUD', 'ETH/AUD', 'XRP/AUD', 'LTC/AUD',
      'BTC/USD', 'ETH/USD', 'XRP/USD', 'LTC/USD',
      'BTC/NZD', 'ETH/NZD', 'XRP/NZD', 'LTC/NZD',
      'BTC/SGD', 'ETH/SGD', 'XRP/SGD', 'LTC/SGD',
      'AVAX/USD', 'AVAX/AUD', 'BNB/USD', 'BNB/AUD',
      'ADA/USD', 'ADA/AUD', 'DOT/USD', 'DOT/AUD',
      'MATIC/USD', 'MATIC/AUD', 'SOL/USD', 'SOL/AUD'
    ];

    // USDT compatibility mode - map USDT pairs to USD pairs for better UX
    // This allows the app to work with USDT pairs even though IR doesn't support them
    this.usdtCompatibilityMode = true;

    // Create a mapping for USDT pairs to their USD equivalents for compatibility mode
    this.usdtToUsdMapping = {
      'BTC/USDT': 'BTC/USD',
      'ETH/USDT': 'ETH/USD',
      'XRP/USDT': 'XRP/USD',
      'LTC/USDT': 'LTC/USD',
      'DOT/USDT': 'DOT/USD',
      'SOL/USDT': 'SOL/USD',
      'ADA/USDT': 'ADA/USD',
      'DOGE/USDT': 'DOGE/USD',
      'AVAX/USDT': 'AVAX/USD',
      'BNB/USDT': 'BNB/USD',
      'MATIC/USDT': 'MATIC/USD'
    };

    // Add virtual USDT pairs to supported pairs for better UX
    if (this.usdtCompatibilityMode) {
      Object.keys(this.usdtToUsdMapping).forEach(usdtPair => {
        if (!this.supportedPairs.includes(usdtPair)) {
          this.supportedPairs.push(usdtPair);
        }
      });
    }

    // Create a mapping for common USDT pairs to fiat equivalents for helpful error messages
    this.usdtToFiatMapping = {
      'BTC/USDT': ['BTC/AUD', 'BTC/USD', 'BTC/NZD', 'BTC/SGD'],
      'ETH/USDT': ['ETH/AUD', 'ETH/USD', 'ETH/NZD', 'ETH/SGD'],
      'XRP/USDT': ['XRP/AUD', 'XRP/USD', 'XRP/NZD', 'XRP/SGD'],
      'LTC/USDT': ['LTC/AUD', 'LTC/USD', 'LTC/NZD', 'LTC/SGD']
    };

    // Currency code mappings
    this.currencyMappings = {
      'BTC': 'Xbt',
      'ETH': 'Eth',
      'USDT': 'Usdt',
      'AUD': 'Aud',
      'USD': 'Usd',
      'NZD': 'Nzd',
      'SGD': 'Sgd',
      'LTC': 'Ltc',
      'XRP': 'Xrp',
      'BCH': 'Bch',
      'EOS': 'Eos',
      'BSV': 'Bsv',
      'LINK': 'Link',
      'ADA': 'Ada',
      'DOT': 'Dot',
      'MATIC': 'Matic',
      'SOL': 'Sol'
    };

    // Pre-initialize markets with known pairs
    this.markets = {};
    this.supportedPairs.forEach(pair => {
      this.markets[pair] = {
        id: this.formatSymbolForApi(pair),
        symbol: pair,
        active: true,
        takerFee: this.takerFee
      };
    });
    
    // Mark markets as loaded since we've manually defined them
    this.marketsLoaded = true;
    
    // Force initialized to true immediately
    this.initialized = true;
  }

  /**
   * Map currency code to Independent Reserve format
   * @param {string} code - Standard currency code (e.g., "BTC")
   * @returns {string} Formatted currency code for IR API (e.g., "Xbt")
   */
  mapCurrency(code) {
    const upperCode = code.toUpperCase();
    
    // Check if we have a specific mapping
    if (this.currencyMappings[upperCode]) {
      return this.currencyMappings[upperCode];
    }
    
    // If no mapping exists, try capitalizing first letter and lowercase rest
    return upperCode.charAt(0).toUpperCase() + upperCode.slice(1).toLowerCase();
  }

  /**
   * Format symbol for Independent Reserve API
   * @param {string} symbol - Trading pair (e.g., "BTC/AUD")
   * @returns {string} Formatted trading pair (e.g., "BTC-AUD")
   */
  formatSymbolForApi(symbol) {
    // Independent Reserve uses dash separator (BTC/AUD -> BTC-AUD)
    return symbol.replace('/', '-');
  }

  /**
   * Convert USDT pair to appropriate fiat pair if in compatibility mode
   * @param {string} symbol - Trading pair (e.g., "BTC/USDT")
   * @returns {string} Converted pair (e.g., "BTC/USD") or original if not applicable
   */
  convertUsdtPairIfNeeded(symbol) {
    // If USDT compatibility mode is enabled and this is a USDT pair with a mapping
    if (this.usdtCompatibilityMode && symbol.endsWith('/USDT') && this.usdtToUsdMapping[symbol]) {
      const usdPair = this.usdtToUsdMapping[symbol];
      console.log(`Independent Reserve compatibility mode: Converting ${symbol} to ${usdPair}`);
      return usdPair;
    }
    
    return symbol;
  }

  /**
   * Split trading pair into primary and secondary currencies
   * @param {string} symbol - Trading pair (e.g., "BTC/AUD")
   * @returns {Object} Object with primaryCode and secondaryCode
   */
  splitPair(symbol) {
    // Convert USDT pairs if needed
    const convertedSymbol = this.convertUsdtPairIfNeeded(symbol);
    
    // First convert to IR format with dash
    const irPair = this.formatSymbolForApi(convertedSymbol);
    
    // Split on dash
    const parts = irPair.split('-');
    if (parts.length !== 2) {
      throw new Error(`Invalid IR pair: '${convertedSymbol}'. Expected format like 'BTC/AUD'.`);
    }
    
    // Map currencies to IR format
    const primaryCode = this.mapCurrency(parts[0]);   // e.g. "BTC" -> "Xbt"
    const secondaryCode = this.mapCurrency(parts[1]); // e.g. "AUD" -> "Aud"
    
    // Handle AVAX specially
    if (parts[0].toUpperCase() === 'AVAX') {
      // Independent Reserve likely uses "Avax" format for AVAX
      const primaryCode = 'Avax';
      return { primaryCode, secondaryCode, originalSymbol: symbol, convertedSymbol };
    }
    
    return { primaryCode, secondaryCode, originalSymbol: symbol, convertedSymbol };
  }

  /**
   * Initialize the exchange - always returns true immediately
   * @returns {Promise<boolean>} Success status
   */
  async initialize() {
    return Promise.resolve(true);
  }

  /**
   * Check if the exchange supports a specific trading pair
   * @param {string} symbol - Trading pair to check
   * @returns {boolean} Whether the exchange supports the pair
   */
  supportsSymbol(symbol) {
    // First check our pre-defined list
    if (this.supportedPairs.includes(symbol)) {
      return true;
    }
    
    // Extract the base and quote currencies
    const parts = symbol.split('/');
    if (parts.length !== 2) {
      return false;
    }
    
    const base = parts[0];
    const quote = parts[1];
    
    // If it's a USDT pair and compatibility mode is on, check if we have a mapping
    if (quote === 'USDT' && this.usdtCompatibilityMode && this.usdtToUsdMapping[symbol]) {
      return true;
    }
    
    // If it's a USDT pair and compatibility mode is off, suggest fiat equivalents
    if (quote === 'USDT' && !this.usdtCompatibilityMode) {
      const alternatives = this.usdtToFiatMapping[symbol];
      
      if (alternatives) {
        // Suggest alternatives with AUD, USD, etc.
        const alternativesStr = alternatives.join(', ');
        const errorMsg = `Independent Reserve doesn't support USDT pairs. Try these alternatives instead: ${alternativesStr}`;
        console.warn(errorMsg);
        throw new Error(errorMsg);
      } else {
        // Generic suggestion for any crypto with fiat pairs
        const errorMsg = `Independent Reserve doesn't support USDT pairs. Try using one of these quote currencies instead: AUD, USD, NZD, SGD.`;
        console.warn(errorMsg);
        throw new Error(errorMsg);
      }
    }
    
    // If it's not a supported fiat currency
    if (!['AUD', 'USD', 'NZD', 'SGD'].includes(quote)) {
      const errorMsg = `Independent Reserve only supports these quote currencies: AUD, USD, NZD, SGD. Got '${quote}' in pair '${symbol}'.`;
      console.warn(errorMsg);
      throw new Error(errorMsg);
    }
    
    return false;
  }

  /**
   * Get taker fee for a symbol
   * @param {string} symbol - Trading pair
   * @returns {number} Taker fee as a decimal
   */
  getTakerFee(symbol) {
    return this.takerFee; // 0.005 = 0.50%
  }

  /**
   * Fetch order book data
   * @param {string} symbol - Trading pair in standard format (e.g., "BTC/AUD")
   * @param {number} limit - Limit the number of orders (not used by IR API directly)
   * @returns {Promise<Object>} Order book with bids and asks
   */
  async fetchOrderBook(symbol, limit = 100) {
    try {
      console.log(`[IndependentReserve] Fetching orderbook for ${symbol} via cache`);
      // Format symbol for cache server
      const formattedSymbol = symbol.replace('/', '_').toUpperCase();
      
      const response = await fetch(`/api/orderbook/${this.id}/${formattedSymbol}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error(`[IndependentReserve] Error fetching orderbook:`, error);
      throw error;
    }
  }
}

export default IndependentReserveExchange; 