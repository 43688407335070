import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import api from './api';

function App() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/api/tokens');
        console.log('Strapi Response:', response);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setError(error.message);
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);

  console.log('Render State:', { data, error, loading });

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        
        {loading && <p>Loading tokens from Strapi...</p>}
        
        {error && <p>Error loading tokens: {error}</p>}
        
        {data && (
          <div>
            <h3>Strapi:</h3>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
