/**
 * Bitget Exchange Implementation
 */
import Exchange from './base-exchange';

class BitgetExchange extends Exchange {
  constructor(options = {}) {
    // Call the parent constructor with Bitget-specific defaults
    super({
      id: 'bitget',
      name: 'Bitget',
      apiUrl: 'https://api.bitget.com',
      takerFee: 0.001, // 0.10% default taker fee
      makerFee: 0.001, // 0.10% default maker fee
      ...options
    });
    
    // Common trading pairs that are supported on Bitget
    this.supportedPairs = [
      'BTC/USDT', 'ETH/USDT', 'XRP/USDT', 'LTC/USDT',
      'BCH/USDT', 'EOS/USDT', 'LINK/USDT', 'DOT/USDT',
      'ADA/USDT', 'DOGE/USDT', 'UNI/USDT', 'AVAX/USDT',
      'SOL/USDT', 'MATIC/USDT', 'BNB/USDT'
    ];

    // Pre-initialize markets with known pairs
    this.markets = {};
    this.supportedPairs.forEach(pair => {
      this.markets[pair] = {
        id: this.formatSymbol(pair),
        symbol: pair,
        active: true,
        takerFee: this.takerFee,
        makerFee: this.makerFee
      };
    });
    
    this.marketsLoaded = true;
    this.feesFetched = true;
    this.initialized = true;
  }

  /**
   * Format symbol for Bitget API
   * @param {string} symbol - Standard trading pair (e.g., "BTC/USDT")
   * @returns {string} Bitget-specific symbol format
   */
  formatSymbol(symbol) {
    // For Bitget spot API, the symbol format is: BTCUSDT_SPBL
    const [base, quote] = symbol.split('/');
    return `${base}${quote}_SPBL`;
  }

  /**
   * Initialize the exchange
   * @returns {Promise<boolean>} Success status
   */
  async initialize() {
    return Promise.resolve(true);
  }

  /**
   * Check if the exchange supports a specific trading pair
   * @param {string} symbol - Trading pair to check
   * @returns {boolean} Whether the exchange supports the pair
   */
  supportsSymbol(symbol) {
    return this.supportedPairs.includes(symbol);
  }

  /**
   * Get taker fee for a symbol
   * @param {string} symbol - Trading pair
   * @returns {number} Taker fee as a decimal
   */
  getTakerFee(symbol) {
    return this.takerFee;
  }

  /**
   * Fetch order book data with fallback
   * @param {string} symbol - Trading pair in standard format (e.g., "BTC/USDT")
   * @returns {Promise<Object>} Order book with bids and asks
   */
  async fetchOrderBook(symbol) {
    try {
      const formattedSymbol = symbol.replace('/', '_');
      const response = await fetch(
        `http://localhost:3001/api/orderbook/bitget/${formattedSymbol}`
      );
      
      if (!response.ok) {
        throw new Error(`Cache server error: ${response.status}`);
      }
      
      const data = await response.json();
      return this.processOrderBookData(data);
    } catch (error) {
      console.error(`Bitget: Error fetching order book for ${symbol}:`, error);
      throw error;
    }
  }

  /**
   * Process and format order book data
   * @param {Object} data - Order book data
   * @returns {Object} - Processed order book data
   */
  processOrderBookData(data) {
    // Apply consistent formatting to the orderbook data
    if (data && data.bids && data.asks) {
      // Ensure we have numeric values before formatting
      data.bids = data.bids.map(bid => {
        // Ensure bid is properly formatted as [price, quantity]
        if (Array.isArray(bid) && bid.length >= 2) {
          const price = typeof bid[0] === 'string' ? parseFloat(bid[0]) : bid[0];
          const quantity = typeof bid[1] === 'string' ? parseFloat(bid[1]) : bid[1];
          return [this.formatDecimal(price), quantity];
        }
        return bid; // Return as is if not in expected format
      });
      
      data.asks = data.asks.map(ask => {
        // Ensure ask is properly formatted as [price, quantity]
        if (Array.isArray(ask) && ask.length >= 2) {
          const price = typeof ask[0] === 'string' ? parseFloat(ask[0]) : ask[0];
          const quantity = typeof ask[1] === 'string' ? parseFloat(ask[1]) : ask[1];
          return [this.formatDecimal(price), quantity];
        }
        return ask; // Return as is if not in expected format
      });
    }
    
    return data;
  }

  /**
   * Format decimal numbers according to the rules
   */
  formatDecimal(num) {
    // Same implementation as before
    if (num === null || num === undefined || isNaN(num)) {
      return 'N/A';
    }
    
    const numValue = typeof num === 'string' ? parseFloat(num) : num;
    
    if (numValue >= 1000) {
      return (numValue / 1000).toPrecision(3) + 'k';
    } else if (numValue < 1) {
      return numValue.toFixed(4).replace('0.', '.');
    } else {
      return numValue.toPrecision(4);
    }
  }
}

export default BitgetExchange; 