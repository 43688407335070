import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import api from './api';
import SupplyPieChart from './components/SupplyPieChart';
import CoinDetail from './components/CoinDetail';
import { setupMobileErrorHandling, isMobile, getMobileDebugInfo } from './utils/mobileHelper';

// Define the API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

const formatMarketCap = (marketCap) => {
  if (!marketCap) return 'N/A';
  if (marketCap >= 1e9) {
    return `$${(marketCap / 1e9).toFixed(2)}B`;
  }
  if (marketCap >= 1e6) {
    return `$${(marketCap / 1e6).toFixed(2)}M`;
  }
  return `$${marketCap.toLocaleString()}`;
};

function App() {
  useEffect(() => {
    // Setup mobile-specific error handling
    setupMobileErrorHandling();
    
    // Log platform info
    console.log(`Platform: ${isMobile() ? 'Mobile' : 'Desktop'}`);
    console.log(`User Agent: ${navigator.userAgent}`);

    // Add debugging output when the app loads
    const mobileInfo = getMobileDebugInfo();
    console.log('App initialized with device info:', mobileInfo);
    
    // Test the API in the console for debugging
    window.testAPI = async (exchange = 'binance', symbol = 'BTC/USDT') => {
      console.log(`Manual API test for ${exchange}/${symbol}`);
      try {
        const response = await fetch(`${API_BASE_URL}/orderbook/${exchange}/${symbol}`);
        console.log('Response status:', response.status);
        const data = await response.json();
        console.log('Data:', data);
        return data;
      } catch (error) {
        console.error('Test API error:', error);
      }
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="bg-blue-600 text-white p-4">
          <div className="container mx-auto text-center text-gray-600">
            <h1 className="text-3xl font-bold"><i><a href="/" style={{ textDecoration: 'none' }}>CryptoFinder</a></i></h1>
          </div>
        </header>

        <main className="container mx-auto p-4">
          <Routes>
            <Route path="/" element={<CoinList />} />
            <Route path="/coin/:id" element={<CoinDetail />} />
          </Routes>
        </main>
        
        <footer className="bg-gray-100 p-4 mt-8">
          <div className="container mx-auto text-center text-gray-600">
            <p>&copy; {new Date().getFullYear()} CryptoFinder</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

function CoinList() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    api.getCoins()
      .then(response => {
        console.log('Full response:', response);
        setData(response);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!data || !data.data) return <div>No data available</div>;

  return (
    <div className="coin-table-container">
      <table className="coin-table">
        <thead>
          <tr>
            <th colSpan="3">Coin</th>
            <th>Market Cap </th>
            <th>Supply</th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((coin) => {
            const attributes = coin.attributes || coin;
            return (
              <tr 
                key={attributes.documentId}
                onClick={() => navigate(`/coin/${attributes.documentId}`)}
                style={{ cursor: 'pointer' }}
              >
                <td>
                  {attributes.featuredImage ? (
                    <img 
                      src={`${process.env.REACT_APP_STRAPI_URL}${
                        attributes.featuredImage.formats?.thumbnail?.url || attributes.featuredImage.url
                      }`}
                      alt={attributes.name}
                      style={{ width: '30px', height: '30px', objectFit: 'contain' }}
                    />
                  ) : (
                    <div 
                      style={{ 
                        width: '30px', 
                        height: '30px', 
                        backgroundColor: '#eee',
                        borderRadius: '50%'
                      }} 
                    />
                  )}
                </td>
                <td>{attributes.symbol}</td>
                <td>{attributes.name}</td>
                <td>{formatMarketCap(attributes.marketCap)}</td>
                <td>
                  <SupplyPieChart 
                    circulatingSupply={attributes.circulatingSupply}
                    maxSupply={attributes.maxSupply}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default App;
