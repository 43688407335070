/**
 * Base Exchange class - defines the interface for all exchange implementations
 * Based on the Python base_exchange.py implementation
 */
class Exchange {
  constructor(options = {}) {
    this.id = options.id || 'unknown';
    this.name = options.name || 'Unknown Exchange';
    this.apiUrl = options.apiUrl || null;
    this.takerFee = options.takerFee || 0.001; // Default 0.1%
    this.makerFee = options.makerFee || 0.001; // Default 0.1%
    this.requiresAuth = options.requiresAuth || false;
    this.apiKey = options.apiKey || null;
    this.apiSecret = options.apiSecret || null;
    this.markets = null;
    this.marketsLoaded = false;
    this.initialized = false;
    this.hasTestMode = options.hasTestMode || false;
    this.testMode = options.testMode || false;
  }

  /**
   * Initialize the exchange
   * @returns {Promise<boolean>} Success status
   */
  async initialize() {
    try {
      await this.loadMarkets();
      this.initialized = true;
      return true;
    } catch (error) {
      console.error(`Failed to initialize ${this.name} exchange:`, error);
      this.initialized = false;
      return false;
    }
  }

  /**
   * Load markets data from the exchange
   * Should be implemented by subclasses
   * @returns {Promise<Object>} Markets data
   */
  async loadMarkets() {
    try {
      const response = await fetch(
        `/api/markets/${this.id}`
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      this.markets = await response.json();
      this.marketsLoaded = true;
      return this.markets;
    } catch (error) {
      throw this.handleError(error, 'loadMarkets');
    }
  }

  /**
   * Load markets if they haven't been loaded yet
   * @returns {Promise<Object>} Markets data
   */
  async loadMarketsIfNeeded() {
    if (!this.marketsLoaded) {
      await this.loadMarkets();
    }
    return this.markets;
  }

  /**
   * Get market information for a symbol
   * @param {string} symbol - Trading pair in standard format (e.g., "BTC/USDT")
   * @returns {Object|null} Market information or null if not found
   */
  getMarket(symbol) {
    return this.markets && this.markets[symbol];
  }

  /**
   * Format a trading pair for this exchange
   * @param {string} symbol - Standard trading pair (e.g., "BTC/USDT")
   * @returns {string} Exchange-specific trading pair
   */
  formatSymbol(symbol) {
    // First normalize to remove any separators
    const normalizedSymbol = symbol.replace(/[/_-]/g, '').toUpperCase();
    
    // Then format according to exchange requirements
    switch (this.id.toLowerCase()) {
      case 'kraken':
        // Kraken has special formatting
        return normalizedSymbol;
      case 'coinbase':
        // Coinbase uses dashes
        if (symbol.includes('/')) {
          return symbol.replace('/', '-');
        }
        return normalizedSymbol;
      default:
        // Most exchanges use no separator
        return normalizedSymbol;
    }
  }

  /**
   * Parse a trading pair from exchange format to standard format
   * @param {string} symbol - Exchange-specific trading pair
   * @returns {string} Standard trading pair (e.g., "BTC/USDT")
   */
  parseSymbol(symbol) {
    // Default implementation, should be overridden by subclasses if needed
    return symbol;
  }

  /**
   * Get the ticker for a symbol
   * @param {string} symbol - Trading pair
   * @returns {Promise<Object>} Ticker data
   */
  async fetchTicker(symbol) {
    if (!this.initialized) {
      await this.initialize();
    }

    try {
      const formattedSymbol = this.formatSymbol(symbol);
      const response = await fetch(
        `/api/ticker/${this.id}/${formattedSymbol}`
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return response.json();
    } catch (error) {
      throw this.handleError(error, 'fetchTicker', { symbol });
    }
  }

  /**
   * Fetch order book data
   * @param {string} symbol - Trading pair
   * @returns {Promise<Object>} Order book with bids and asks
   */
  async fetchOrderBook(symbol) {
    try {
      const response = await fetch(`/api/orderbook/${this.id}/${symbol.replace('/', '_')}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        
        // Check for specific error types
        if (errorData.error && (
            errorData.error.includes('not supported') ||
            errorData.error.includes('does not have market symbol') ||
            errorData.error.includes('market not found')
          )) {
          throw new Error(`Coin not supported by this exchange`);
        }
        
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error(`[${this.name}] Error fetching orderbook:`, error);
      throw error;
    }
  }

  /**
   * Check if the exchange supports a specific trading pair
   * @param {string} symbol - Trading pair to check
   * @returns {boolean} Whether the exchange supports the pair
   */
  supportsSymbol(symbol) {
    // Strip out any separators for comparison
    const normalizedSymbol = symbol.replace(/[/_-]/g, '').toUpperCase();
    
    // Handle special cases for certain exchanges
    if (this.id.toLowerCase() === 'kraken') {
      // Kraken uses different symbols, so we need special handling
      // Convert BTC/USD to XXBTZUSD format or similar
      return true; // For now, let's assume supported to fix display
    }
    
    // Log for debugging
    console.log(`Checking if ${this.id} supports ${symbol} (normalized: ${normalizedSymbol})`);
    
    // Return true for common pairs to ensure display
    const commonPairs = ['BTCUSDT', 'ETHUSDT', 'BNBUSDT'];
    const normalizedCommonPairs = commonPairs.map(p => p.replace(/[/_-]/g, '').toUpperCase());
    
    if (normalizedCommonPairs.includes(normalizedSymbol)) {
      return true;
    }
    
    // If we have markets loaded, check properly
    if (this.markets) {
      // Check if the symbol exists in our markets
      return Object.keys(this.markets).some(marketSymbol => {
        const normalizedMarketSymbol = marketSymbol.replace(/[/_-]/g, '').toUpperCase();
        return normalizedMarketSymbol === normalizedSymbol;
      });
    }
    
    // Default to true for popular pairs if markets aren't loaded
    return true;
  }

  /**
   * Get the taker fee for a specific trading pair
   * @param {string} symbol - Trading pair
   * @returns {number} Taker fee as a decimal (e.g., 0.001 for 0.1%)
   */
  getTakerFee(symbol) {
    // Check if we have a specific fee for this symbol
    if (this.marketsLoaded && this.markets && this.markets[symbol] && this.markets[symbol].takerFee) {
      return this.markets[symbol].takerFee;
    }
    return this.takerFee;
  }

  /**
   * Get the maker fee for a specific trading pair
   * @param {string} symbol - Trading pair
   * @returns {number} Maker fee as a decimal (e.g., 0.001 for 0.1%)
   */
  getMakerFee(symbol) {
    // Check if we have a specific fee for this symbol
    if (this.marketsLoaded && this.markets && this.markets[symbol] && this.markets[symbol].makerFee) {
      return this.markets[symbol].makerFee;
    }
    return this.makerFee;
  }

  /**
   * Handle API errors consistently
   * @param {Error} error - The error object
   * @param {string} method - The method that caused the error
   * @param {Object} params - The parameters passed to the method
   * @returns {Error} A standardized error object
   */
  handleError(error, method, params = {}) {
    // Create a standardized error with exchange info
    const standardError = new Error(`${this.name} exchange error in ${method}: ${error.message}`);
    standardError.exchangeId = this.id;
    standardError.exchangeName = this.name;
    standardError.originalError = error;
    standardError.method = method;
    standardError.params = params;
    
    // Log the error for debugging
    console.error(standardError);
    
    return standardError;
  }
}

export default Exchange; 