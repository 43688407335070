/**
 * Bybit Exchange Implementation
 */
import Exchange from './base-exchange';

class BybitExchange extends Exchange {
  constructor(options = {}) {
    // Call the parent constructor with Bybit-specific defaults
    super({
      id: 'bybit',
      name: 'Bybit',
      apiUrl: 'https://api.bybit.com',
      // Standard Bybit fee structure (API requires authentication)
      takerFee: 0.001, // 0.10%  
      makerFee: 0.001, // 0.10%
      ...options
    });
    
    // Common trading pairs that are supported on Bybit
    this.supportedPairs = [
      'BTC/USDT', 'ETH/USDT', 'XRP/USDT', 'LTC/USDT',
      'BCH/USDT', 'EOS/USDT', 'LINK/USDT', 'DOT/USDT',
      'ADA/USDT', 'DOGE/USDT', 'SOL/USDT', 'AVAX/USDT',
      'MATIC/USDT', 'BNB/USDT'
    ];

    // Pre-initialize markets with known pairs
    this.markets = {};
    this.supportedPairs.forEach(pair => {
      this.markets[pair] = {
        id: pair.replace('/', ''),
        symbol: pair,
        active: true,
        takerFee: this.takerFee,
        makerFee: this.makerFee
      };
    });
    
    this.marketsLoaded = true;
    this.feesFetched = true;
    this.initialized = true;
  }

  async initialize() {
    return Promise.resolve(true);
  }

  supportsSymbol(symbol) {
    return this.supportedPairs.includes(symbol);
  }

  getTakerFee(symbol) {
    return this.takerFee;
  }

  formatSymbol(symbol) {
    return symbol.replace('/', '');
  }

  async fetchOrderBook(symbol) {
    const response = await fetch(
      `/api/orderbook/bybit/${symbol.replace('/', '_')}`
    );
    
    if (!response.ok) {
      throw new Error(`Cache server error: ${response.status}`);
    }
    
    return response.json();
  }
}

export default BybitExchange; 