import { getFallbackData as getFallbackOrderBook, isMobile } from './utils/mobileHelper';

const API_URL = process.env.REACT_APP_STRAPI_URL;

const get = async (endpoint) => {
  const fullUrl = `${API_URL}${endpoint}`;
  console.log('Calling API:', fullUrl); // Debug URL

  if (!API_URL) {
    throw new Error('REACT_APP_STRAPI_URL is not configured');
  }

  try {
    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
      },
    });
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error('API Error Response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }
    
    return response.json();
  } catch (error) {
    console.error('API Call Failed:', error);
    throw error;
  }
};

export default {
  get,
  getCoins: () => get('/api/coins?populate=*'),
  getCoin: (id) => get(`/api/coins/${id}?populate=*`)
};

// Ensure all API calls go through the cache server
const API_BASE_URL = '/api';

export const fetchOrderBook = async (exchange, symbol) => {
  try {
    // Normalize symbol format
    const formattedSymbol = symbol.replace('/', '_').toUpperCase();
    console.log(`[API] Fetching orderbook for ${exchange}/${formattedSymbol}`);
    
    // Set longer timeout for mobile devices
    const timeout = isMobile() ? 15000 : 10000;
    
    // Use AbortController for timeouts
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);
    
    const response = await fetch(
      `/api/orderbook/${exchange.toLowerCase()}/${formattedSymbol}`,
      { signal: controller.signal }
    );
    
    clearTimeout(timeoutId);
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error(`[API] Error fetching orderbook:`, error);
    
    // For mobile devices, provide fallback data instead of failing
    if (isMobile()) {
      console.log(`[API] Using fallback data for ${exchange}/${symbol} on mobile`);
      return getFallbackOrderBook(symbol);
    }
    
    throw error;
  }
};

export const fetchMarkets = async (exchange) => {
  try {
    const response = await fetch(`${API_BASE_URL}/markets/${exchange.toLowerCase()}`);
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error(`[API] Error fetching markets:`, error);
    throw error;
  }
};

export const fetchTicker = async (exchange, symbol) => {
  try {
    // Normalize symbol format
    const formattedSymbol = symbol.replace('/', '_').toUpperCase();
    
    const response = await fetch(`${API_BASE_URL}/ticker/${exchange.toLowerCase()}/${formattedSymbol}`);
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error(`[API] Error fetching ticker:`, error);
    throw error;
  }
};
