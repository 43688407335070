/**
 * CoinSpot Exchange Implementation
 * Based on the Python implementation
 */
import Exchange from './base-exchange';
import { getJson } from '../utils/api-utils';

class CoinSpotExchange extends Exchange {
  constructor(options = {}) {
    // Call the parent constructor with CoinSpot-specific defaults
    super({
      id: 'coinspot',
      name: 'CoinSpot',
      apiUrl: 'https://www.coinspot.com.au',
      takerFee: 0.001, // 0.10% default taker fee
      ...options
    });
    
    // Common trading pairs that are supported on CoinSpot
    // CoinSpot only supports AUD as the quote currency
    this.supportedPairs = [
      'BTC/AUD', 'ETH/AUD', 'XRP/AUD', 'LTC/AUD', 'BCH/AUD',
      'DOGE/AUD', 'SOL/AUD', 'ADA/AUD', 'DOT/AUD', 'MATIC/AUD'
    ];

    // Create a mapping for common USDT pairs to AUD pairs for helpful error messages
    this.usdtToAudMapping = {
      'BTC/USDT': 'BTC/AUD',
      'ETH/USDT': 'ETH/AUD',
      'XRP/USDT': 'XRP/AUD',
      'DOT/USDT': 'DOT/AUD',
      'SOL/USDT': 'SOL/AUD'
    };

    // Pre-initialize markets with known pairs
    this.markets = {};
    this.supportedPairs.forEach(pair => {
      this.markets[pair] = {
        id: this.formatSymbolForApi(pair),
        symbol: pair,
        active: true,
        takerFee: this.takerFee
      };
    });
    
    // Mark markets as loaded since we've manually defined them
    this.marketsLoaded = true;
    
    // Force initialized to true immediately
    this.initialized = true;
  }

  /**
   * Format symbol for CoinSpot API
   * @param {string} symbol - Trading pair (e.g., "BTC/AUD")
   * @returns {string} Formatted symbol for API (e.g., "btc")
   */
  formatSymbolForApi(symbol) {
    const parts = symbol.split('/');
    if (parts.length !== 2) {
      throw new Error(`Invalid pair format: '${symbol}'. Expected format like 'BTC/AUD'.`);
    }
    // Return only the base currency in lowercase
    return parts[0].toLowerCase();
  }

  /**
   * Initialize the exchange - always returns true immediately
   * @returns {Promise<boolean>} Success status
   */
  async initialize() {
    return Promise.resolve(true);
  }

  /**
   * Check if the exchange supports a specific trading pair
   * @param {string} symbol - Trading pair to check
   * @returns {boolean} Whether the exchange supports the pair
   */
  supportsSymbol(symbol) {
    // Check our pre-defined list
    if (this.supportedPairs.includes(symbol)) {
      return true;
    }
    
    // Extract the quote currency (second part after the slash)
    const parts = symbol.split('/');
    if (parts.length !== 2) {
      return false;
    }
    
    const quote = parts[1];
    
    // If not AUD, provide helpful error message
    if (quote !== 'AUD') {
      // If it's a USDT pair, suggest AUD equivalent
      if (quote === 'USDT') {
        const alternativePair = this.usdtToAudMapping[symbol];
        const baseAsset = parts[0];
        
        const errorMsg = alternativePair 
          ? `CoinSpot only supports AUD as the quote currency. Try ${alternativePair} instead of ${symbol}.`
          : `CoinSpot only supports AUD as the quote currency. Try ${baseAsset}/AUD instead of ${symbol}.`;
          
        console.warn(errorMsg);
        throw new Error(errorMsg);
      } else {
        throw new Error(`CoinSpot only supports AUD as the quote currency. Got '${quote}' in pair '${symbol}'.`);
      }
    }
    
    return false;
  }

  /**
   * Get taker fee for a symbol
   * @param {string} symbol - Trading pair
   * @returns {number} Taker fee as a decimal
   */
  getTakerFee(symbol) {
    return this.takerFee; // 0.001 = 0.10%
  }

  /**
   * Fetch order book data
   * @param {string} symbol - Trading pair in standard format (e.g., "BTC/AUD")
   * @param {number} limit - Limit the number of orders (not used by CoinSpot API)
   * @returns {Promise<Object>} Order book with bids and asks
   */
  async fetchOrderBook(symbol, limit = 100) {
    try {
      // Check if pair is supported and has correct quote currency
      if (!this.supportsSymbol(symbol)) {
        throw new Error(`Trading pair ${symbol} not supported by ${this.id}`);
      }
      
      // Split the pair to get base and quote currencies
      const parts = symbol.split('/');
      if (parts.length !== 2) {
        throw new Error(`Invalid pair format: '${symbol}'. Expected format like 'BTC/AUD'.`);
      }
      
      const base = parts[0].toLowerCase(); // Base currency (e.g., btc)
      const quote = parts[1].toUpperCase(); // Quote currency (should be AUD)
      
      // Validate that quote currency is AUD
      if (quote !== 'AUD') {
        throw new Error(`CoinSpot only supports AUD as the quote currency. Got '${quote}' in pair '${symbol}'.`);
      }
      
      // Create the URL for the API call
      const url = `${this.apiUrl}/pubapi/v2/orders/open/${base}`;
      
      // Fetch the order book data
      const data = await getJson(url);
      
      // Check for API errors and expected data structure
      if (!data || typeof data !== 'object') {
        throw new Error(`Unexpected response format from CoinSpot API for '${symbol}'`);
      }
      
      // Process buy orders (bids) and sell orders (asks)
      const bids = [];
      const asks = [];
      
      // Process buy orders (bids)
      // Aggregate orders at same price level
      const bidLevels = {};
      if (Array.isArray(data.buyorders)) {
        data.buyorders.forEach(order => {
          const price = parseFloat(order.rate || 0);
          const amount = parseFloat(order.amount || 0);
          
          if (price > 0 && amount > 0) {
            if (bidLevels[price]) {
              bidLevels[price] += amount;
            } else {
              bidLevels[price] = amount;
            }
          }
        });
        
        // Convert aggregated bids to sorted list
        Object.entries(bidLevels).forEach(([price, totalAmount]) => {
          bids.push([parseFloat(price), totalAmount]);
        });
        
        // Sort bids highest to lowest
        bids.sort((a, b) => b[0] - a[0]);
      }
      
      // Process sell orders (asks)
      // Aggregate orders at same price level
      const askLevels = {};
      if (Array.isArray(data.sellorders)) {
        data.sellorders.forEach(order => {
          const price = parseFloat(order.rate || 0);
          const amount = parseFloat(order.amount || 0);
          
          if (price > 0 && amount > 0) {
            if (askLevels[price]) {
              askLevels[price] += amount;
            } else {
              askLevels[price] = amount;
            }
          }
        });
        
        // Convert aggregated asks to sorted list
        Object.entries(askLevels).forEach(([price, totalAmount]) => {
          asks.push([parseFloat(price), totalAmount]);
        });
        
        // Sort asks lowest to highest
        asks.sort((a, b) => a[0] - b[0]);
      }
      
      return {
        symbol: symbol,
        bids: bids,
        asks: asks,
        timestamp: Date.now(),
        datetime: new Date().toISOString()
      };
    } catch (error) {
      throw new Error(`Failed to fetch CoinSpot order book: ${error.message}`);
    }
  }
}

export default CoinSpotExchange; 