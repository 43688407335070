/**
 * API Manager Module
 * Coordinates exchange access and provides a unified interface
 * Based on the Python exchanges implementations
 */

import { createExchange, getSupportedExchangeIds } from './exchanges/index';
import { fetchOrderBook, fetchMarkets, fetchTicker } from '../api';

/**
 * ExchangeManager handles the management of multiple crypto exchanges
 * and provides methods to interact with them in a consistent way
 */
class ExchangeManager {
  constructor() {
    // List of supported exchanges from our implementation
    this.supportedExchangeIds = getSupportedExchangeIds();
    
    // Exchange instances
    this.exchanges = {};
    
    // Exchange initialization status
    this.initialized = false;
    
    // Exchange initialization promises
    this.initPromises = {};
    
    console.log("Exchange Manager created with support for exchanges:", this.supportedExchangeIds);
  }

  /**
   * Initialize all supported exchanges
   * @returns {Promise<void>}
   */
  async initialize() {
    if (this.initialized) return;
    
    console.log("Initializing exchanges...");
    
    // Create promises for each exchange initialization
    for (const id of this.supportedExchangeIds) {
      this.initPromises[id] = this.initializeExchange(id);
    }
    
    // Wait for all exchanges to initialize
    await Promise.allSettled(Object.values(this.initPromises));
    
    this.initialized = true;
    
    console.log("Exchange initialization complete.");
    console.log("Successfully initialized exchanges:", 
      Object.keys(this.exchanges).filter(id => this.exchanges[id].initialized));
  }

  /**
   * Initialize a specific exchange
   * @param {string} exchangeId - Exchange ID to initialize
   * @returns {Promise<boolean>} Success status
   */
  async initializeExchange(exchangeId) {
    try {
      console.log(`Initializing ${exchangeId} exchange...`);
      
      // Create exchange instance
      console.log(`Creating ${exchangeId} exchange instance...`);
      const exchange = createExchange(exchangeId);
      
      if (!exchange) {
        console.error(`Failed to create ${exchangeId} exchange instance`);
        return false;
      }
      
      console.log(`Successfully created ${exchangeId} exchange instance, calling initialize()...`);
      
      // Initialize the exchange (load markets, etc.)
      const success = await exchange.initialize();
      
      if (success) {
        console.log(`Successfully initialized ${exchangeId} exchange, setting in exchanges map`);
        this.exchanges[exchangeId] = exchange;
        return true;
      } else {
        console.warn(`Failed to initialize ${exchangeId} exchange - initialize() returned false`);
        return false;
      }
    } catch (error) {
      console.error(`Error initializing ${exchangeId} exchange:`, error);
      return false;
    }
  }

  /**
   * Get a list of supported exchanges
   * @returns {Array} Array of exchange objects with id, name
   */
  getSupportedExchanges() {
    return this.supportedExchangeIds.map(id => {
      const exchange = this.exchanges[id];
      return {
        id,
        name: exchange ? exchange.name : this.getExchangeName(id),
        initialized: !!exchange,
        status: exchange ? 'available' : 'unavailable'
      };
    });
  }
  
  /**
   * Get a user-friendly exchange name
   * @param {string} id - Exchange ID
   * @returns {string} Formatted exchange name
   */
  getExchangeName(id) {
    // Map ids to nicer display names
    const nameMap = {
      'binance': 'Binance',
      'kraken': 'Kraken',
      'coinbase': 'Coinbase',
      'bybit': 'Bybit',
      'coinjar': 'CoinJar',
      'okx': 'OKX',
      'kucoin': 'KuCoin',
      'bitget': 'Bitget',
      'gemini': 'Gemini',
      'btcmarkets': 'BTCMarkets',
      'coinspot': 'CoinSpot',
      'independentreserve': 'IndependentReserve',
      'luno': 'Luno'
    };
    
    return nameMap[id] || id.charAt(0).toUpperCase() + id.slice(1);
  }

  /**
   * Get order book data from an exchange
   * @param {string} exchangeId - Exchange ID
   * @param {string} symbol - Trading pair (e.g., "BTC/USDT")
   * @returns {Promise<Object>} Order book data with bids and asks
   */
  async getOrderBook(exchangeId, symbol) {
    if (!this.initialized) {
      await this.initialize();
    }
    
    const exchange = this.exchanges[exchangeId];
    if (!exchange) {
      throw new Error(`Exchange ${exchangeId} not available`);
    }
    
    try {
      // Use the environment variable from .env file
      const API_BASE = process.env.REACT_APP_API_BASE;
      
      // Debug the environment variable (remove after debugging)
      console.log("Using API_BASE:", process.env.REACT_APP_API_BASE);
      
      const response = await fetch(
        `${API_BASE}/api/orderbook/${exchangeId.toLowerCase()}/${symbol.replace('/', '_')}`
      );
      
      if (!response.ok) {
        throw new Error(`Cache server error: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error(`Error fetching order book for ${exchangeId} ${symbol}:`, error);
      throw error;
    }
  }
  
  /**
   * Calculate slippage and execution details from order book data
   * @param {Object} orderBook - Order book data
   * @param {number} amount - Amount to trade
   * @param {string} action - "buy" or "sell"
   * @returns {Object} Execution details including slippage and average price
   */
  calculateExecutionFromOrderBook(orderBook, amount, action) {
    // Check if order book is valid
    if (!orderBook || !orderBook.bids || !orderBook.asks || 
        orderBook.bids.length === 0 || orderBook.asks.length === 0) {
      console.error("Invalid order book data:", orderBook);
      throw new Error('Invalid order book data');
    }
    
    const relevantSide = action === 'buy' ? orderBook.asks : orderBook.bids;
    
    // Check if we have data on the relevant side
    if (relevantSide.length === 0) {
      console.error(`No ${action === 'buy' ? 'ask' : 'bid'} data available`);
      throw new Error(`No ${action === 'buy' ? 'ask' : 'bid'} data available`);
    }
    
    // Helper function to safely access price/quantity regardless of format
    const getPrice = (level) => {
      if (Array.isArray(level)) {
        return parseFloat(level[0]);
      } else if (level.price !== undefined) {
        return parseFloat(level.price);
      }
      console.warn("Unknown price format:", level);
      return 0;
    };
    
    const getQuantity = (level) => {
      if (Array.isArray(level)) {
        return parseFloat(level[1]);
      } else if (level.quantity !== undefined) {
        return parseFloat(level.quantity);
      }
      console.warn("Unknown quantity format:", level);
      return 0;
    };
    
    // Get best price (first level price)
    const bestPrice = getPrice(relevantSide[0]);
    
    console.log(`Best ${action === 'buy' ? 'ask' : 'bid'} price: ${bestPrice}`);
    
    if (isNaN(bestPrice) || bestPrice <= 0) {
      console.error("Invalid best price:", bestPrice, "for level:", relevantSide[0]);
      throw new Error('Invalid price data in order book');
    }
    
    // Calculate average price by walking the order book
    let remainingAmount = amount;
    let totalCost = 0;
    let filled = false;
    
    for (const level of relevantSide) {
      const price = getPrice(level);
      const quantity = getQuantity(level);
      
      if (isNaN(price) || isNaN(quantity)) {
        console.warn("Skipping invalid level:", level);
        continue;
      }
      
      const fillAmount = Math.min(remainingAmount, quantity);
      const fillCost = fillAmount * price;
      
      totalCost += fillCost;
      remainingAmount -= fillAmount;
      
      if (remainingAmount <= 0) {
        filled = true;
        break;
      }
    }
    
    // If we couldn't fill the entire order
    if (!filled) {
      // Estimate the rest with a 10% worse price than the last level
      const lastPrice = getPrice(relevantSide[relevantSide.length - 1]);
      const adjustedPrice = action === 'buy' ? lastPrice * 1.1 : lastPrice * 0.9;
      totalCost += remainingAmount * adjustedPrice;
    }
    
    // Calculate average execution price
    const averagePrice = totalCost / amount;
    
    // Calculate spread
    const topBid = orderBook.bids.length > 0 ? getPrice(orderBook.bids[0]) : 0;
    const topAsk = orderBook.asks.length > 0 ? getPrice(orderBook.asks[0]) : 0;
    
    const spread = (topBid > 0 && topAsk > 0) ? 
      ((topAsk - topBid) / topAsk) * 100 : 0;
    
    // Calculate slippage as percentage difference between best price and average price
    const slippage = action === 'buy' 
      ? ((averagePrice - bestPrice) / bestPrice) * 100
      : ((bestPrice - averagePrice) / bestPrice) * 100;
    
    console.log(`Calculated average price: ${averagePrice}, slippage: ${slippage}%, spread: ${spread}%`);
    
    return {
      averagePrice,
      slippage: Math.max(slippage, 0),
      spread,
      bestBid: topBid,
      bestAsk: topAsk,
      filled,
      // Include first few levels for market depth visualization
      marketDepth: {
        bids: orderBook.bids.slice(0, 5),
        asks: orderBook.asks.slice(0, 5)
      }
    };
  }

  /**
   * Compare prices across exchanges for a trading pair
   * @param {string} symbol - Trading pair (e.g., "BTC/USDT")
   * @param {number} amount - Amount to trade
   * @param {string} action - "buy" or "sell"
   * @returns {Promise<Array>} Comparison results
   */
  async compareExchanges(symbol, amount, action) {
    // Ensure the manager is initialized
    if (!this.initialized) {
      await this.initialize();
    }
    
    const comparisonPromises = Object.entries(this.exchanges).map(async ([exchangeId, exchange]) => {
      try {
        // Check if exchange supports this symbol
        if (!exchange.supportsSymbol(symbol)) {
          throw new Error(`Trading pair ${symbol} not supported by ${exchangeId}`);
        }
        
        // Fetch the order book
        const orderBook = await this.getOrderBook(exchangeId, symbol);
        
        // Get the taker fee
        const fee = exchange.getTakerFee(symbol);
        
        // Calculate execution details
        const executionDetails = this.calculateExecutionFromOrderBook(orderBook, amount, action);
        
        // Calculate total cost and fees
        const totalCost = executionDetails.averagePrice * amount;
        const feeAmount = totalCost * fee;
        const effectiveCost = action === 'buy' ? totalCost + feeAmount : totalCost - feeAmount;
        
        return {
          exchangeId,
          exchangeName: exchange.name,
          averagePrice: executionDetails.averagePrice,
          fee: fee * 100, // Convert to percentage
          slippage: executionDetails.slippage,
          spread: executionDetails.spread,
          bestBid: executionDetails.bestBid,
          bestAsk: executionDetails.bestAsk,
          effectiveCost,
          effectiveProceeds: totalCost - feeAmount,
          filled: executionDetails.filled,
          marketDepth: executionDetails.marketDepth
        };
      } catch (error) {
        console.warn(`Error processing ${exchangeId}:`, error);
        return {
          exchangeId,
          exchangeName: this.getExchangeName(exchangeId),
          error: true,
          errorMessage: error.message
        };
      }
    });
    
    // Handle exchanges that couldn't be initialized
    const unavailableExchanges = this.supportedExchangeIds
      .filter(id => !this.exchanges[id])
      .map(id => ({
        exchangeId: id,
        exchangeName: this.getExchangeName(id),
        error: true,
        errorMessage: 'Exchange not available or could not be initialized'
      }));

    try {
      // Wait for all comparison results
      const results = await Promise.all(comparisonPromises);
      
      // Filter out errors and sort by price (best first)
      const validResults = results.filter(r => !r.error);
      const errorResults = results.filter(r => r.error);
      
      // Combine valid results, errors, and unavailable exchanges
      return [...validResults, ...errorResults, ...unavailableExchanges];
    } catch (error) {
      console.error("Error comparing exchanges:", error);
      return [{
        error: true,
        errorMessage: `Failed to compare exchanges: ${error.message}`,
        globalError: true
      }];
    }
  }
}

// Create and export a singleton instance
const exchangeManager = new ExchangeManager();

// Start initialization in the background
exchangeManager.initialize().catch(error => {
  console.error("Failed to initialize exchanges:", error);
});

export default exchangeManager; 